<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="closeModal(false)"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ $t('Nova angariação externa') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>
        <vue-perfect-scrollbar
          class="b-sidebar-scrollarea-form"
          :settings="configSettingsScroll"
        >
          <b-container
            class="pt-2 pb-0 pl-2 pr-2"
            fluid
          >
            <validation-observer
              ref="formAngariacaoExterna"
              #default="{}"
            >
              <b-row>
                <b-col md="12">
                  <b-form-group
                    :label="$t('Nickname do imóvel')"
                  ><validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('Nickname do imóvel') | lowerBold"
                  >
                    <b-form-input
                      v-model="form_data.sw012s03"
                      placeholder=""
                      autocomplete="off"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    />
                  </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    :label="$t('ID Externo')"
                  >
                    <b-form-input
                      v-model="form_data.sw070s30"
                      placeholder=""
                      autocomplete="off"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('Tipo') | lowerBold"
                  >
                    <b-form-group
                      :label="$t('Tipo')"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <v-select
                        id="fSW070_flttipo"
                        v-model="form_data.sw070s31"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="arrayTiposImoveisCategoria"
                        label="sw087s02"
                        item-text="sw087s02"
                        item-value="sw087s01"
                        :class="errors.length > 0 ? 'is-invalid':null"
                        @input="getListImoveis"
                      >
                        <template #option="{ sw087s02 }">
                          {{ sw087s02 }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>

                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('Imóvel') | lowerBold"
                  >
                    <b-form-group
                      :label="$t('Imóvel')"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <v-select
                        id="fSW070_fltimovel"
                        v-model="form_data.sw070s32"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="arrayTiposImoveis"
                        :class="errors.length > 0 ? 'is-invalid':null"
                        :loading="loadingImoveis"
                        label="desc"
                        item-text="desc"
                        item-value="id"
                        @input="getListTipologias"
                      >
                        <template #option="{ desc }">
                          {{ desc }}
                        </template>
                        <div
                          v-if="form_data.sw070s31 !== null && form_data.sw070s31 !== undefined && form_data.sw070s31 !== ''"
                          slot="no-options"
                        >
                          {{ $t('Sem resultados') }}
                        </div>
                        <div
                          v-if="form_data.sw070s31 === null || form_data.sw070s31 === undefined || form_data.sw070s31 === ''"
                          slot="no-options"
                        >
                          {{ $t('Escolha um tipo') }}
                        </div>
                      </v-select>
                    </b-form-group>
                  </validation-provider></b-col>
              </b-row>
              <b-row
                v-if="['PT'].includes(countryApp.toUpperCase())"
              >
                <b-col md="12">
                  <validation-provider
                    v-if="showTipologia"
                    #default="{ errors }"
                    rules="required"
                    :name="$t('Tipologia') | lowerBold"
                  >
                    <b-form-group
                      v-if="showTipologia"
                      :label="$t('Tipologia')"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <v-select
                        v-if="showTipologia"
                        id="fSW070_flttipologia"
                        v-model="form_data.sw070s33"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="arrayClassesTipologias"
                        :loading="loadingTipologia"
                        :class="errors.length > 0 ? 'is-invalid':null"
                        label="sw005s02"
                        item-text="sw005s02"
                        item-value="sw005s01"
                        @input="getExtrasTipologias"
                      >
                        <template #option="{ sw005s02 }">
                          {{ sw005s02 }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>

                      <div
                        v-if="form_data.sw070s31 !== null && form_data.sw070s31 !== undefined"
                        slot="no-options"
                      >
                        {{ $t('Sem resultados') }}
                      </div>
                      <div
                        v-if="form_data.sw070s31 === null || form_data.sw070s31 === undefined"
                        slot="no-options"
                      >
                        {{ $t('Escolha um imóvel') }}
                      </div>
                    </b-form-group>
                  </validation-provider></b-col>
              </b-row>
              <b-row
                v-if="['PT'].includes(countryApp.toUpperCase())"
              >
                <b-col md="12">
                  <b-form-group
                    v-if="showExtraTipologia"
                    :label="$t('Tipologia extra')"
                    :name="$t('Tipologia extra') | lowerBold"
                  >
                    <v-select
                      v-if="showExtraTipologia"
                      id="fSW070_flttipo"
                      v-model="form_data.sw070s34"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="arrayExtraTipologias"
                      :loading="loadingExtraTipologia"
                      label="sw005s02"
                      item-text="sw005s02"
                      item-value="sw005s01"
                    >
                      <template #option="{ sw005s02 }">
                        {{ sw005s02 }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>

                    <div
                      v-if="form_data.sw070s33 !== null && form_data.sw070s33 !== undefined"
                      slot="no-options"
                    >
                      {{ $t('Sem resultados') }}
                    </div>
                    <div
                      v-if="form_data.sw070s33 === null || form_data.sw070s33 === undefined"
                      slot="no-options"
                    >
                      {{ $t('Escolha uma tipologia') }}
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                v-if="['ES', 'AR'].includes(countryApp.toUpperCase())"
              >
                <b-col md="12">
                  <validation-provider
                    v-if="showTipologia"
                    #default="{ errors }"
                    rules="required"
                    :name="$t('Quartos') | lowerBold"
                  >
                    <b-form-group
                      v-if="showTipologia"
                      :label="$t('Quartos')"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <v-select
                        v-if="showTipologia"
                        id="fSW070_flttipologia"
                        v-model="form_data.sw070s42"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="array70"
                        :loading="loadingTipologia"
                        :class="errors.length > 0 ? 'is-invalid':null"
                        label="desc"
                        item-text="desc"
                        item-value="id"
                      >
                        <template #option="{ desc }">
                          {{ desc }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>

                      <div
                        v-if="form_data.sw070s32 !== null && form_data.sw070s32 !== undefined"
                        slot="no-options"
                      >
                        {{ $t('Sem resultados') }}
                      </div>
                      <div
                        v-if="form_data.sw070s32 === null || form_data.sw070s32 === undefined"
                        slot="no-options"
                      >
                        {{ $t('Escolha um imóvel') }}
                      </div>
                    </b-form-group>
                  </validation-provider></b-col>
              </b-row>
              <b-row
                v-if="['ES', 'AR'].includes(countryApp.toUpperCase())"
              >
                <b-col md="12">
                  <validation-provider
                    v-if="showExtraTipologia"
                    #default="{ errors }"
                    rules="required"
                    :name="$t('Casa de banho') | lowerBold"
                  >
                    <b-form-group
                      v-if="showExtraTipologia"
                      :label="$t('Casa de banho')"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <v-select
                        v-if="showExtraTipologia"
                        id="fSW070_flttipologia"
                        v-model="form_data.sw070s43"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="array70"
                        :loading="loadingTipologia"
                        :class="errors.length > 0 ? 'is-invalid':null"
                        label="desc"
                        item-text="desc"
                        item-value="id"
                      >
                        <template #option="{ desc }">
                          {{ desc }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>

                      <div
                        v-if="form_data.sw070s32 !== null && form_data.sw070s32 !== undefined"
                        slot="no-options"
                      >
                        {{ $t('Sem resultados') }}
                      </div>
                      <div
                        v-if="form_data.sw070s32 === null || form_data.sw070s32 === undefined"
                        slot="no-options"
                      >
                        {{ $t('Escolha um imóvel') }}
                      </div>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    v-if="showTipologiaExtra"
                    :label="$t('Especial?')"
                    :name="$t('Especial?') | lowerBold"
                  >
                    <v-select
                      v-if="showTipologiaExtra"
                      id="fSW070_flttipo"
                      v-model="form_data.sw070s35"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="arrayTipologiasExtra"
                      :loading="loadingTipologiaEspecial"
                      multiple
                      label="sw004s02"
                      item-text="sw004s02"
                      item-value="sw004s01"
                      @input="validateSW019s03"
                    >
                      <template #option="{ sw004s02 }">
                        {{ sw004s02 }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>

                    <div
                      v-if="form_data.sw070s31 !== null && form_data.sw070s31 !== undefined"
                      slot="no-options"
                    >
                      {{ $t('Sem resultados') }}
                    </div>
                    <div
                      v-if="form_data.sw070s31 === null || form_data.sw070s31 === undefined"
                      slot="no-options"
                    >
                      {{ $t('Escolha um imóvel') }}
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-if="showTipoTerreno"
                  md="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('Tipo de terreno') | lowerBold"
                  >
                    <b-form-group
                      v-if="showTipoTerreno"
                      :label="$t('Tipo de terreno')"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <v-select
                        v-if="showTipoTerreno"
                        id="fSW070_flttipoterreno"
                        v-model="form_data.sw070s40"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="arrayTipoTerreno"
                        :class="errors.length > 0 ? 'is-invalid':null"
                        label="sw008s02"
                        item-text="sw008s02"
                        item-value="sw008s01"
                      >
                        <template #option="{ sw008s02 }">
                          {{ sw008s02 }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>

                      <div
                        v-if="form_data.sw070s31 !== null && form_data.sw070s31 !== undefined"
                        slot="no-options"
                      >
                        {{ $t('Sem resultados') }}
                      </div>
                      <div
                        v-if="form_data.sw070s31 === null || form_data.sw070s31 === undefined"
                        slot="no-options"
                      >
                        {{ $t('Escolha um imóvel') }}
                      </div>
                    </b-form-group>
                  </validation-provider></b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('Tipo de negócio') | lowerBold"
                  >
                    <b-form-group
                      :label="$t('Tipo de negócio')"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <v-select
                        id="fSW070_flttiponegocio"
                        v-model="form_data.sw070s36"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="arrayTipoNegocio"
                        :class="errors.length > 0 ? 'is-invalid':null"
                        label="sw007s02"
                        item-text="sw007s02"
                        item-value="sw007s01"
                      >
                        <template #option="{ sw007s02 }">
                          {{ sw007s02 }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>
                    </b-form-group>
                  </validation-provider></b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('Preço') | lowerBold"
                  >
                    <b-form-group
                      :label="$t('Preço')"
                    >
                      <b-input-group
                        :prepend="symbolMoney"
                        append=""
                        :class="errors.length > 0 ? 'is-invalid input-group-merge': 'input-group-merge'"
                      >
                        <b-form-input
                          v-model="form_data.sw070s37"
                          v-currency="configMoney"
                          placeholder=""
                          autocomplete="off"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        />
                      </b-input-group>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <component
                :is="blockLocalization"
                ref="location"
              />

              <b-row>
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('Comissão') | lowerBold"
                  >
                    <b-form-group
                      :label="$t('Comissão')"
                    >
                      <b-input-group
                        prepend="%"
                        append=""
                        :class="errors.length > 0 ? 'is-invalid input-group-merge': 'input-group-merge'"
                      >
                        <b-form-input
                          v-model="form_data.sw070s38"
                          :raw="false"
                          :options="options.number"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          min="0"
                          max="100"
                          @keypress="NumbersDecimal"
                        />
                      </b-input-group>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('Mínimo definido no CMI') | lowerBold"
                  >
                    <b-form-group
                      :label="$t('Mínimo definido no CMI')"
                    >

                      <b-input-group
                        :prepend="symbolMoney"
                        append=""
                        :class="errors.length > 0 ? 'is-invalid input-group-merge': 'input-group-merge'"
                      >
                        <b-form-input
                          v-model="form_data.sw070s51"
                          v-currency="configMoney"
                          placeholder=""
                          autocomplete="off"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        />
                      </b-input-group>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    :label="$t('Link externo')"
                  >
                    <b-form-input
                      v-model="form_data.sw070s52"
                      placeholder=""
                      autocomplete="off"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </b-container>

        </vue-perfect-scrollbar>
        <div
          class="d-flex p-1 justify-content-center"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mr-2"
            @click.stop.prevent="closeModal(false)"
          >
            {{ $t('Fechar') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click.stop.prevent="saveModal"
          >
            {{ $t('Guardar') }}
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import store from '@/store'
import {
  BSidebar, BContainer, BRow, BCol, VBTooltip, BButton, BFormGroup, BFormInput, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { configMoney, symbolMoney } from '@core-custom/utils/money'
import { CurrencyDirective } from 'vue-currency-input'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../listview/mixins'

export default {
  components: {
    BSidebar,
    BContainer,
    BRow,
    BCol,
    BButton,
    VuePerfectScrollbar,
    vSelect,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    BInputGroup,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    currency: CurrencyDirective,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
    showMsgErrorRequest: {
      type: Function,
      required: true,
    },
    showMsgSuccessRequest: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      modalData: [],

      loadingImoveis: false,
      loadingTipologia: false,
      loadingExtraTipologia: false,
      loadingTipologiaEspecial: false,

      configMoney,
      symbolMoney,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
    }
  },
  computed: {
    ...mapGetters('crm_visitas_form', ['form_data', 'arrayTiposImoveisCategoria', 'arrayTiposImoveis', 'arrayClassesTipologias', 'arrayTipologiasExtra', 'arrayExtraTipologias', 'arrayTipoTerreno', 'arrayTipoNegocio', 'showTipologia', 'showTipologiaExtra', 'showExtraTipologia', 'showTipoTerreno', 'flagAngariacaoExternaSaved', 'array70']),
    ...mapGetters('auth', ['countryApp']),
  },
  methods: {
    async closeModal(val) {
      await this.$store.dispatch('crm_visitas_form/resetAngariacaoExterna').catch(error => {
        this.showMsgErrorRequest(error)
      })

      this.$parent.showFormNovaAngariacaoExterna = val
    },
    async getListImoveis() {
      this.loadingImoveis = true

      await this.$store.dispatch('crm_visitas_form/loadListImoveis').catch(error => {
        this.showMsgErrorRequest(error)
      })
      this.loadingImoveis = false
    },
    async getListTipologias() {
      await this.$store.dispatch('crm_visitas_form/resetTipologias').catch(error => {
        this.showMsgErrorRequest(error)
      })

      if (this.form_data.sw070s32 !== undefined && this.form_data.sw070s32 !== null && this.form_data.sw070s32 !== '') {
        if (this.form_data.sw070s32.show_cl_tipologia !== undefined && this.form_data.sw070s32.show_cl_tipologia === '1') {
          await this.$store.dispatch('crm_visitas_form/setShowTipologia', true).catch(error => {
            this.showMsgErrorRequest(error)
          })

          if (this.form_data.sw070s32.escolha_especial !== undefined && this.form_data.sw070s32.escolha_especial === '1') {
            this.loadingTipologiaEspecial = true
            await this.$store.dispatch('crm_visitas_form/loadSubTipologias').catch(error => {
              this.showMsgErrorRequest(error)
            })
            this.loadingTipologiaEspecial = false
          }
        }

        if (['ES', 'AR'].includes(this.countryApp.toUpperCase())) {
          if (this.form_data.sw070s32.show_tipo_terreno === undefined || this.form_data.sw070s32.show_tipo_terreno !== '1') {
            await this.$store.dispatch('crm_visitas_form/setShowTipologia', true).catch(error => {
              this.showMsgErrorRequest(error)
            })
          }

          if (![3, 15, 19, 119, 155, 156, 157, 197, 206, 216, 228, 224].includes(Number(this.form_data.sw070s32.id))) {
            await this.$store.dispatch('crm_visitas_form/setShowExtraTipologia', true).catch(error => {
              this.showMsgErrorRequest(error)
            })
          }

          if (this.form_data.sw070s32.escolha_especial !== undefined && this.form_data.sw070s32.escolha_especial === '1' && (this.form_data.sw070s32.show_tipo_terreno === '' || this.form_data.sw070s32.show_tipo_terreno === undefined)) {
            this.loadingTipologiaEspecial = true
            await this.$store.dispatch('crm_visitas_form/loadSubTipologias').catch(error => {
              this.showMsgErrorRequest(error)
            })
            this.loadingTipologiaEspecial = false
          }
        }

        if (this.form_data.sw070s32.show_tipo_terreno !== undefined && this.form_data.sw070s32.show_tipo_terreno === '1') {
          await this.$store.dispatch('crm_visitas_form/setShowTipoTerreno', true).catch(error => {
            this.showMsgErrorRequest(error)
          })
        }
      }
      // escolha_especial especial
    },
    async getExtrasTipologias() {
      // tipologias/getExtras

      await this.$store.dispatch('crm_visitas_form/resetExtraTipologias').catch(error => {
        this.showMsgErrorRequest(error)
      })

      if (this.form_data.sw070s33 !== undefined && this.form_data.sw070s33 !== null && this.form_data.sw070s33 !== '') {
        if (this.form_data.sw070s32.escolha_especial !== undefined && this.form_data.sw070s32.escolha_especial === '1') {
          this.loadingExtraTipologia = true
          await this.$store.dispatch('crm_visitas_form/loadExtraTipologias').catch(error => {
            this.showMsgErrorRequest(error)
          })
          this.loadingExtraTipologia = false
        }
      }
    },
    async saveModal() {
      this.$root.$emit('app::loading', true)

      // await this.$refs.formAngariacaoExterna.validateWithInfo().then(success => {
      //   if (success) {
      //     this.$parent.showFormNovaAngariacaoExterna = false
      //   } else {
      //     this.$root.$emit('app::loading', false)
      //     this.showMsgErrorRequest({ message: this.$t('Preencha os campos obrigatórios') })
      //   }
      // })

      await this.$refs.formAngariacaoExterna.validateWithInfo()
        .then(async ({
          isValid, errors,
        }) => {
          if (isValid) {
            this.$root.$emit('app::loading', false)
            await this.$store.dispatch('crm_visitas_form/setAngariacaoExternaSaved', true).catch(error => {
              this.showMsgErrorRequest(error)
            })
            this.$parent.showFormNovaAngariacaoExterna = false
          } else {
            throw errors
          }
        })
        .catch(async error => {
          this.$root.$emit('app::loading', false)
          this.showMsgErrorRequestForm(error)
        })
    },
    async showMsgErrorRequestForm(error) {
      let messageAlertError = ''

      if (error instanceof Error) {
        if (error.message !== '') {
          messageAlertError += `<h3 class="text-center text-danger mb-2">${this.$t('Alerta')}</h3>`
          messageAlertError += `<p class="mb-1">${error.message}</p>`
        }
      } else if (Object.keys(error).length > 0) {
        if ((Object.keys(error).length === 1) && ('message' in error) && (error.message === '')) {
          messageAlertError = ''
        } else {
          messageAlertError += `<h3 class="text-center text-danger mb-2">${this.$tc('Preencha o campo obrigatório', Object.keys(error).length)}</h3>`
          Object.keys(error).forEach(key => {
            if (error[key].length > 0) {
              error[key].forEach(txt => {
                messageAlertError += `<p class="mb-1">${txt}</p>`
              })
            }
          })
        }
      }

      if (messageAlertError !== '') {
        this.$swal({
          title: '',
          html: messageAlertError,
          icon: '',
          width: 600,
          confirmButtonText: this.$t('Fechar'),
          customClass: {
            confirmButton: 'btn btn-outline-primary',
            htmlContainer: 'text-left',
            content: 'pl-0 pr-0 pl-sm-2 pr-sm-2',
          },
          buttonsStyling: false,
        })
      }
    },
    NumbersDecimal(evt) {
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
        return false
      }
      return true
    },
    validateSW019s03(vlEspecial) {
      let count = 0
      const aPos = []

      Array.from(vlEspecial).forEach((item, index) => {
        if (item.sw004s02.toLowerCase() === 'duplex' || item.sw004s02.toLowerCase() === 'triplex') {
          count += 1
          aPos.push(index)
        }

        if (count === 2) {
          this.showMsgErrorRequest(new Error(this.$t('Não pode ter a opção Duplex e Triplex selecionadas. Escolha uma opção.')))

          let nSplice = 0
          aPos.forEach(pos => {
            const key = pos - nSplice

            vlEspecial.splice(key, 1)
            nSplice += 1
          })
        }
      })
    },
  },
  setup() {
    const countryApp = store.getters['auth/countryApp']
    const blockLocalization = () => import(/* webpackChunkName: "crm-visitas-form-localization" */ /* webpackPrefetch: true */ `./localization/codPostal_${countryApp}.vue`)

    return {
      blockLocalization,
      configSettingsScroll,
    }
  },
}
</script>
