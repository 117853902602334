var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"sidebar-class":"sidebar-lg","visible":"","bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function($event){return _vm.closeModal(false)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('Novo agente externo'))+" ")]),_c('div',[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":_vm.sizeIconsAction},on:{"click":hide}})],1)]),_c('vue-perfect-scrollbar',{staticClass:"b-sidebar-scrollarea-form",attrs:{"settings":_vm.configSettingsScroll}},[_c('b-container',{staticClass:"pt-2 pb-0 pl-2 pr-2",attrs:{"fluid":""}},[_c('validation-observer',{ref:"formNovoAgente",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Rede imobiliária')}},[_c('p',{staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(_vm.form_data.sw070s21.sw128s02 || '----')+" ")])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Agência')}},[_c('p',{staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(_vm.form_data.sw070s41.designacao_comercial || '----')+" ")])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Nome')}},[_c('b-form-input',{class:{'form-control' : true, 'is-invalid': errors.length > 0 },attrs:{"placeholder":"","autocomplete":"off"},model:{value:(_vm.form_data.sw025s03),callback:function ($$v) {_vm.$set(_vm.form_data, "sw025s03", $$v)},expression:"form_data.sw025s03"}})],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Apelido')}},[_c('b-form-input',{class:{'form-control' : true, 'is-invalid': errors.length > 0 },attrs:{"placeholder":"","autocomplete":"off"},model:{value:(_vm.form_data.sw025s04),callback:function ($$v) {_vm.$set(_vm.form_data, "sw025s04", $$v)},expression:"form_data.sw025s04"}})],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":{numberVueTelInput: _vm.numberPhoneValidate }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Telefone')}},[_c('vue-tel-input',{ref:"phone",staticClass:"form-control",class:errors.length > 0 ? 'is-invalid':null,attrs:{"inputmode":"tel","mode":_vm.configPhone.mode,"valid-characters-only":_vm.configPhone.validCharactersOnly,"auto-format":_vm.configPhone.autoFormat,"default-country":_vm.configPhone.defaultCountry,"auto-default-country":_vm.configPhone.autoDefaultCountry,"input-options":_vm.configPhone.inputOptions,"dropdown-options":_vm.configPhone.dropdownOptions},on:{"input":_vm.checkValidateNumberPhone,"open":_vm.onDropdownOpenTelInput},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }$event.preventDefault();return $event.target.blur()},"!blur":function($event){$event.preventDefault();return _vm.inputValidateNumberPhone(_vm.$refs.phone)}},model:{value:(_vm.form_data.sw025s05),callback:function ($$v) {_vm.$set(_vm.form_data, "sw025s05", $$v)},expression:"form_data.sw025s05"}},[_c('template',{slot:"arrow-icon"},[_vm._v(" "+_vm._s('\xa0')+" ")]),_c('template',{slot:"icon-right"},[_vm._v(" "+_vm._s('\xa0')+" ")])],2)],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Email')}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"placeholder":"","autocomplete":"off","inputmode":"email"},model:{value:(_vm.form_data.sw025s06),callback:function ($$v) {_vm.$set(_vm.form_data, "sw025s06", $$v)},expression:"form_data.sw025s06"}})],1)]}}],null,true)})],1)],1)]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex p-1 justify-content-center"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"outline-secondary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.closeModal(false)}}},[_vm._v(" "+_vm._s(_vm.$t('Fechar'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('save-novo-agente')}}},[_vm._v(" "+_vm._s(_vm.$t('Guardar'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }