<template>
  <ul
    class="email-media-list"
  >
    <b-media
      v-for="(row) in listviewData"
      :key="row.id"
      tag="li"
      no-body
      @click.stop.prevent="openModalDetailByRow(row)"
    >
      <b-media-body>
        <div class="mail-details">
          <b-container
            fluid
          >
            <b-row>
              <b-col md="12">
                <h5
                  v-if="row.pid"
                  class=""
                >
                  {{ row.code_visita }} - {{ row.pid }}
                </h5>

              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <span
                  v-if="row.tipologia_angariacao"
                  class=""
                >
                  {{ row.tipologia_angariacao }}
                </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <span
                  v-if="row.local_angariacao"
                  class=""
                >
                  {{ row.local_angariacao }}
                </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="row.nome_angariacao"
                md="3 mt-1"
              >
                <span
                  v-if="row.nome_angariacao"
                >
                  <feather-icon
                    icon="UserIcon"
                  />
                  {{ row.nome_angariacao }}
                </span>
                <div
                  v-if="row.nome_angariacao"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Angariador') }}
                  </p>
                </div>
              </b-col>
              <b-col
                v-if="row.name_consultor"
                md="3 mt-1"
              >
                <span
                  v-if="row.name_consultor"
                >
                  <feather-icon
                    icon="UserIcon"
                  />
                  {{ row.name_consultor }}
                </span>
                <div
                  v-if="row.name_consultor"
                  class="mail-message"
                >
                  <p
                    v-if="row.name_consultor"
                    class="mb-0"
                  >
                    {{ $t('Consultor') }}
                  </p>
                </div>
              </b-col>
              <b-col md="3 mt-1">
                <span
                  v-if="row.name_lead"
                >
                  <feather-icon
                    icon="UserIcon"
                  />
                  {{ row.name_lead }} <small v-if="row.name_agent_visit">{{ row.name_agent_visit }}</small>
                </span>
                <br>
                <span
                  v-if="row.contacto_lead"
                >
                  <feather-icon
                    icon="PhoneIcon"
                  />
                  {{ row.contacto_lead }}
                </span>
                <div
                  v-if="row.name_lead"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $tc('Lead', 1) }} / {{ $tc('Consultor', 1) }}
                  </p>
                </div>
              </b-col>
              <b-col md="3 mt-1">
                <span
                  v-if="row.data_estado"
                >
                  {{ row.data_estado }}
                </span>
                <br>
                <div
                  v-if="row.data_estado"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Data de registo') }}
                  </p>
                </div>
              </b-col>
              <b-col md="3 mt-1">
                <span
                  v-if="row.data_visita"
                >
                  {{ row.data_visita }}
                </span>
                <br>
                <div
                  v-if="row.data_visita"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Data da visita') }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>

        <!-- Tipo de cliente -->

        <!-- Itens de alerta -->
      </b-media-body>
    </b-media>
  </ul>
</template>

<script>
import {
  BMedia, BMediaBody, VBTooltip, BRow, BCol, BContainer,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BMedia,
    BMediaBody,
    BRow,
    BCol,
    BContainer,

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    openModalDetailByRow: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('crm_visitas_listview', ['listviewData', 'filterSelected', 'labelData']),
  },
  methods: {},
}
</script>
