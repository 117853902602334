<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div
          :class="{'email-app-menu': true}"
        >
          <div
            v-if="permCreateVisit === true && forceHideButtonsVisita == false && showSimpleFormVisita == false && showSimpleFormLeadExternaVisita == false && showSimpleFormAngariacaoExternaVisita == false"
            class="form-group-compose text-center compose-btn"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="$emit('open-nova-visita');$emit('close-left-sidebar')"
            >
              {{ $t('Nova visita') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              block
              @click="$emit('open-nova-visita-externa');$emit('close-left-sidebar')"
            >
              {{ $t('Nova visita externa') }}
            </b-button>
          </div>
          <div
            v-if="showSimpleFormVisita == true || showSimpleFormLeadExternaVisita == true || showSimpleFormAngariacaoExternaVisita == true"
            class="form-group-compose text-center compose-btn"
          >
            <b-button
              v-if="flagShowConcluir && showSimpleFormVisita"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              block
              @click="$emit('concluir-nova-visita')"
            >
              {{ $t('Concluir') }}
            </b-button>
            <b-button
              v-if="showSimpleFormVisita"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="$emit('save-nova-visita')"
            >
              {{ $t('Guardar') }}
            </b-button>
            <b-button
              v-if="flagShowConcluir && showSimpleFormLeadExternaVisita"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              block
              @click="$emit('concluir-nova-visita-lead-externa')"
            >
              {{ $t('Concluir') }}
            </b-button>
            <b-button
              v-if="showSimpleFormLeadExternaVisita"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="$emit('save-nova-visita-lead-externa')"
            >
              {{ $t('Guardar') }}
            </b-button>
            <b-button
              v-if="flagShowConcluir && showSimpleFormAngariacaoExternaVisita"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              block
              @click="$emit('concluir-nova-visita-angariacao-externa')"
            >
              {{ $t('Concluir') }}
            </b-button>
            <b-button
              v-if="showSimpleFormAngariacaoExternaVisita"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="$emit('save-nova-visita-angariacao-externa')"
            >
              {{ $t('Guardar') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              block
              @click="$emit('cancel-nova-visita')"
            >
              {{ $t('Cancelar') }}
            </b-button>
          </div>
          <vue-perfect-scrollbar
            v-if="showSimpleFormVisita == false && showSimpleFormLeadExternaVisita == false && showSimpleFormAngariacaoExternaVisita == false"
            ref="filterScroll"
            class="sidebar-menu-list scroll-area"
            :settings="configSettingsScroll"
          >

            <div class="mt-2 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                {{ $t('Separador') }}
              </h6>
            </div>

            <b-list-group
              v-if="filterData.listviewFilterSeparadores.length > 0"
              class="list-group-messages"
            >
              <b-list-group-item
                v-for="oTab in filterData.listviewFilterSeparadores.filter((oTab) => oTab.show === true)"
                :key="oTab.key"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.separadorSelecionado === oTab.key) ? true : false)"
                @click.stop.prevent="listviewFilterTab(oTab.key);$emit('close-left-sidebar')"
              >
                <span class="align-text-bottom line-height-1">{{ oTab.text }}</span>
              </b-list-group-item>
            </b-list-group>

            <div
              v-if="filterData.listviewFilterHubs.length > 0"
              class="mt-3 px-2 d-flex justify-content-between"
            >
              <h6 class="section-label mb-1">
                {{ $t('Hubs') }}
              </h6>
            </div>

            <v-select
              v-if="filterData.listviewFilterHubs.length > 0"
              id="fSW404_fltHub"
              v-model="filterSelected.hubsSelecionados"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filterData.listviewFilterHubs"
              class="mb-1 ml-2 mr-2"
              label="desc"
              item-text="desc"
              item-value="id"
              @input="selectFilterOptions(['fltHub', $event])"
            >
              <template #option="{ desc }">
                {{ desc }}
              </template>
              <div slot="no-options">
                {{ $t('Sem resultados') }}
              </div>
            </v-select>

            <div
              v-if="filterData.listviewFilterGeral.length > 0"
              class="mt-3 px-2 d-flex justify-content-between"
            >
              <h6 class="section-label mb-1">
                {{ $t('Filtro') }}
              </h6>
            </div>

            <b-list-group
              v-if="filterData.listviewFilterGeral.length > 0"
              class="list-group-messages"
            >
              <b-list-group-item
                v-for="oFilter in filterData.listviewFilterGeral"
                :key="oFilter.id"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.filtroSelecionados.value.includes(oFilter.id)) ? true : false)"
                @click.stop.prevent="listviewFilterOption(oFilter.id);$emit('close-left-sidebar')"
              >
                <span class="align-text-bottom line-height-1">{{ oFilter.desc }}</span>
              </b-list-group-item>
            </b-list-group>

            <div
              v-if="filterData.listviewFilterResponsaveis.length > 0"
              class="mt-3 px-2 d-flex justify-content-between"
            >
              <h6 class="section-label mb-1">
                {{ $t('Responsável') }}
              </h6>
            </div>

            <v-select
              v-if="filterData.listviewFilterResponsaveis.length > 0"
              id="fltResponsavel"
              v-model="filterSelected.responsavelSelecionado"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filterData.listviewFilterResponsaveis"
              class="mb-1 ml-2 mr-2"
              label="desc"
              item-text="desc"
              item-value="id"
              append-to-body
              :calculate-position="withPopper"
              @input="selectFilterOptions(['fltResponsavel', $event])"
            >
              <template #option="{ desc }">
                {{ desc }}
              </template>
              <div slot="no-options">
                {{ $t('Sem resultados') }}
              </div>
            </v-select>

            <div
              v-if="filterSelectedTotalFields > 0"
              class="form-group-compose text-center compose-btn"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                block
                class="mr-1"
                @click="resetListviewFilter();$emit('close-left-sidebar')"
              >
                <span
                  class="align-middle"
                >
                  {{ $t('Limpar filtros') }}
                </span>
              </b-button>
            </div>

            <h6
              class="section-label mt-3 mb-1 px-2"
            >
              &nbsp;
            </h6>

          </vue-perfect-scrollbar>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import {
  BListGroup, BListGroupItem, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mask } from 'vue-the-mask'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mapGetters, mapActions } from 'vuex'
import { configSettingsScroll } from '@core-custom/utils/ui'
import checkNested from 'check-nested'
import { mixFormGeral } from './mixins'

export default {
  directives: {
    Ripple,
    mask,
  },
  components: {
    VuePerfectScrollbar,
    BListGroup,
    BListGroupItem,
    vSelect,
    BButton,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
    showMsgErrorRequest: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      maskDate: {
        date: true,
        delimiter: '-',
        datePattern: ['Y', 'm', 'd'],
      },
      search: [],
    }
  },
  computed: {
    ...mapGetters('crm_visitas_listview', ['filterData', 'filterSelected', 'filterSelectedTotalFields', 'filterControl', 'forceHideButtonsVisita']),
    ...mapGetters('crm_visitas_form', ['showSimpleFormVisita', 'showSimpleFormLeadExternaVisita', 'showSimpleFormAngariacaoExternaVisita', 'flagShowConcluir']),
    permCreateVisit() {
      const oCurrentUser = this.$store.getters['auth/currentUser']

      if (oCurrentUser !== null && oCurrentUser !== undefined && checkNested(oCurrentUser, 'extras.basic') && oCurrentUser.extras.basic === 1) {
        return true
      }

      if (oCurrentUser !== null && oCurrentUser !== undefined && 'extras' in oCurrentUser && 'perm' in oCurrentUser.extras
        && (Object.values(oCurrentUser.extras.perm).includes('pode_marcar_visitas_leads') || Object.values(oCurrentUser.extras.perm).includes('pode_marcar_visitas_leads_edita') || Object.values(oCurrentUser.extras.perm).includes('marca_visitas_outros_membros'))) {
        return true
      }

      return false
    },
  },
  methods: {
    ...mapActions('crm_visitas_listview', ['selectFilterOptions']),
    encodeID(id) {
      return btoa(id)
    },
    listviewFilterTab(tab) {
      if (tab === 3 || tab === 1) {
        this.$store.dispatch('crm_visitas_listview/setlistviewFilterOrderBy', '5').catch(error => {
          this.showMsgErrorRequest(error)
        })
      } else {
        this.$store.dispatch('crm_visitas_listview/setlistviewFilterOrderBy', '6').catch(error => {
          this.showMsgErrorRequest(error)
        })
      }

      this.$store.dispatch('crm_visitas_listview/listviewFilterTab', tab).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewFilterOption(option) {
      this.$store.dispatch('crm_visitas_listview/listviewFilterOption', option).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    searchByUserAuth(text) {
      store.dispatch('sw001/searchConsultoresByLocationMktAuth', text).then(res => {
        this.search = [{ data: res }]
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    resetListviewFilter() {
      this.$store.dispatch('crm_visitas_listview/resetListviewFilterGeral').catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.email-application .content-area-wrapper .sidebar .email-app-sidebar .email-app-menu .sidebar-menu-list{
  position: relative;
  height: calc(100%);
  padding-bottom: 100px;
}
</style>
