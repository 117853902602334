<template>
  <div class="email-app-details bg-white">

    <div class="email-detail-header">
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('cancel-nova-visita')"
          />
        </span>
        <h4
          v-if="visitaEditMode"
          class="email-subject mb-0"
        >
          {{ $t('Editar visita externa') }}
        </h4>
        <h4
          v-else
          class="email-subject mb-0"
        >
          {{ $t('Nova visita externa') }}
        </h4>
      </div>
    </div>

    <vue-perfect-scrollbar
      class="email-scroll-area scroll-area pr-0 pl-0"
      :settings="configSettingsScroll"
    >

      <b-container
        fluid
        class="p-2"
      >
        <validation-observer
          ref="formNovaVisitaAngariacaoExterna"
          #default="{}"
        >
          <b-row
            v-if="flagAngariacaoExternaSaved == true || flagAngariacaoExternaAdded == true"
            class="mb-2"
          >
            <b-col
              md="6"
              class="d-flex justify-content-between flex-column"
            >
              <!-- User Avatar & Action Buttons -->
              <div class="d-flex justify-content-start">
                <b-avatar
                  :src="arrayAngariacao.imagem + '?v=' + new Date().getTime()"
                  size="110px"
                  rounded
                />
                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h5 class="mb-0">
                      {{ arrayAngariacao.pid }}
                    </h5>
                    <span class="card-text"> {{ arrayAngariacao.tipologia }} </span><br>
                    <span class="card-text"> {{ arrayAngariacao.nomeConsultor }} </span>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row
            class="mb-2"
          >
            <b-col md="3 mt-1">
              <b-button
                v-if="flagAngariacaoExternaAdded == false"
                block
                variant="outline-primary"
                @click.stop.prevent="openModalAngariacao(true)"
              >
                <span class="align-middle">{{ $t('Adicionar angariação externa') }}</span>
              </b-button>
              <b-button
                v-else
                block
                variant="outline-primary"
                @click.stop.prevent="openModalAngariacao(true)"
              >
                <span class="align-middle">{{ $t('Alterar angariação externa') }}</span>
              </b-button>
            </b-col>
            <b-col md="3 mt-1">
              <b-button
                v-if="flagAngariacaoExternaSaved == false"
                block
                variant="outline-warning"
                @click="$emit('form-nova-angariacao-externa')"
              >
                <span class="align-middle">{{ $t('Criar angariação externa') }}</span>
              </b-button>
              <b-button
                v-else
                block
                variant="outline-warning"
                @click="$emit('form-nova-angariacao-externa')"
              >
                <span class="align-middle">{{ $t('Alterar angariação externa') }}</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row v-if="arrayConsultoresTeam.length > 0">
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                rules="required"
                :name="$t('Tipo')"
              >
                <b-form-group
                  :label="$t('Escolha um consultor')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    id="fSW070_flttipo"
                    v-model="form_data.sw070s02"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="arrayConsultoresTeam"
                    label="nome"
                    item-text="nome"
                    item-value="id"
                    :class="errors.length > 0 ? 'is-invalid':null"
                    @input="setListLeads"
                  >
                    <template #option="{ nome }">
                      {{ nome }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>

                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider
                ref="sw070s09input"
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  :class="errors.length > 0 ? 'is-invalid':null"
                  :label="$tc('Lead', 1)"
                >
                  <v-select
                    v-model="form_data.sw070s09"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="listLeadsApp"
                    append-to-body
                    :calculate-position="withPopper"
                    :loading="isLoadingLeads"
                    :filterable="false"
                    :placeholder="$t('Digite o nome do Lead que procura')"
                    label="desc"
                    item-text="desc"
                    item-value="id"
                    @search="onSearchLeadsAppSelect"
                    @input="setOptionSelectLead"
                  >
                    <div
                      v-if="listLeadsApp.length > 0"
                      slot="no-options"
                    >
                      {{ $t('Sem resultados') }}
                    </div>
                    <template
                      v-if="listLeadsApp.length === 0"
                      slot="no-options"
                    >
                      {{ $t('Digite o nome') }}
                    </template>
                    <template
                      v-if="form_data.sw070s09 !== null && arrayConsultoresTeam.length > 0 && (form_data.sw070s02 === undefined || form_data.sw070s02 === null)"
                      slot="no-options"
                    >
                      {{ $t('Selecione um consultor') }}
                    </template>
                    <template
                      slot="option"
                      slot-scope="option"
                    >
                      <div class="d-flex align-items-center">
                        <b-avatar
                          :src="option.photo"
                          class="mr-50"
                        />
                        <div class="detail">
                          <b-card-text class="mb-0">
                            {{ option.desc }}
                          </b-card-text>
                          <b-card-text
                            v-if="option.email"
                            class="mb-0 text-muted"
                          >
                            <small>{{ option.email }}</small>
                          </b-card-text>
                          <b-card-text
                            v-if="option.phone"
                            class="mb-0 text-muted"
                          >
                            <small>{{ option.phone }}</small>
                          </b-card-text>
                          <b-card-text
                            v-if="option.tipo"
                            class="mb-0 text-muted"
                          >
                            <small>{{ option.tipo }}</small>
                          </b-card-text>
                        </div>
                      </div>
                    </template>
                    <template
                      slot="selected-option"
                      slot-scope="option"
                    >
                      {{ option.desc }}
                    </template>
                  </v-select>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  :label="$t('Rede imobiliária')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    id="fSW070_flt128"
                    v-model="form_data.sw070s21"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="arraySW128"
                    class="mb-1"
                    label="sw128s02"
                    item-text="sw128s02"
                    item-value="sw128s01"
                    @input="getAgenciasList"
                  >
                    <template #option="{ sw128s02 }">
                      {{ sw128s02 }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                </b-form-group>
              </validation-provider></b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  :label="$t('Agência')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    id="fSW070_flt172"
                    v-model="form_data.sw070s41"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="arraySW172"
                    class="mb-50"
                    :loading="loadingAgenciasFlag"
                    label="designacao_comercial"
                    item-text="designacao_comercial"
                    item-value="id"
                    @input="getListAgentes"
                  >
                    <template #option="{ designacao_comercial }">
                      {{ designacao_comercial }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Escolha uma rede imobiliária') }}
                    </div>
                  </v-select>
                  <a
                    class="company-name text-primary"
                    @click.stop.prevent="$emit('form-nova-agencia')"
                  > Não encontra? </a>

                </b-form-group>
              </validation-provider></b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  :label="$t('Agente')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    id="fSW070_flt025"
                    v-model="form_data.sw070s46"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="arraySW025"
                    :loading="loadingAgentesFlag"
                    class="mb-50"
                    label="nome"
                    item-text="nome"
                    item-value="id_encode"
                  >
                    <template #option="{ nome }">
                      {{ nome }}
                    </template>
                    <div
                      v-if="form_data.sw070s41 !== null && form_data.sw070s41 !== undefined"
                      slot="no-options"
                    >
                      {{ $t('Sem resultados') }}
                    </div>
                    <div
                      v-if="form_data.sw070s41 === null || form_data.sw070s41 === undefined"
                      slot="no-options"
                    >
                      {{ $t('Escolha uma agência') }}
                    </div>
                  </v-select>
                  <a
                    class="company-name text-primary"
                    @click.stop.prevent="$emit('form-novo-agente')"
                  > Não encontra? </a>
                </b-form-group>
              </validation-provider></b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  :label="$t('Data da visita')"
                >
                  <date-picker
                    v-model="form_data.sw070s10"
                    value-type="format"
                    :input-class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                    type="date"
                    :editable="false"
                    :clearable="true"
                    :lang="langCalendar"
                    :append-to-body="true"
                    prefix-class="sw"
                    @change="checkConcluirAction"
                  >
                    <template #icon-clear>
                      <v-select-deselect />
                    </template>
                  </date-picker>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  :label="$t('Hora da visita')"
                >
                  <b-form-timepicker
                    v-model="form_data.sw070s10_time"
                    placeholder="00:00"
                    hide-header
                    now-button
                    :label-close-button="$t('Fechar')"
                    :label-now-button="$t('Hora atual')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                    @input="checkConcluirAction"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('Observação')"
              >
                <b-form-textarea
                  v-model="form_data.sw070s12"
                  placeholder=""
                  rows="3"
                />
              </b-form-group>
            </b-col>
          </b-row>

        </validation-observer>
      </b-container>
      <div
        class="d-flex p-1 justify-content-center  d-block d-sm-none"
      >
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          class="mr-1"
          @click.stop.prevent="$emit('cancel-nova-visita')"
        >
          {{ $t('Cancelar') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1"
          type="submit"
          @click.stop.prevent="$emit('save-nova-visita')"
        >
          {{ $t('Guardar') }}
        </b-button>
        <b-button
          v-if="flagShowConcluir"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="warning"
          @click.stop.prevent="$emit('concluir-nova-visita')"
        >
          {{ $t('Concluir') }}
        </b-button>
      </div>
      <b-overlay
        :show="$store.getters['crm_visitas_listview/loadingListview']"
        no-wrap
      />

    </vue-perfect-scrollbar>
  </div>

</template>

<script>
import {
  BRow, BCol, BButton, VBTooltip, BFormGroup, BContainer, BOverlay, BFormTimepicker, BFormTextarea, BAvatar, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Dialog } from '@capacitor/dialog'
import useCapacitor from '@core-custom/utils/capacitor'
import { sizeIconsAction, debounce, VeeValidateLoadLocale } from '@core-custom/mixins/geral'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { mapGetters, mapActions } from 'vuex'
// import { format, isAfter, parseISO } from 'date-fns'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../listview/mixins'

const oDatepicker = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ 'vue2-datepicker'),
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ '@/assets/scss/datepicker.scss'),
]).then(([DatePicker]) => DatePicker)

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BAvatar,
    BFormGroup,
    BContainer,
    VuePerfectScrollbar,
    BOverlay,
    BFormTimepicker,
    ValidationProvider,
    vSelectDeselect: vSelect.components.Deselect,
    vSelect,
    DatePicker: oDatepicker,
    BFormTextarea,
    ValidationObserver,
    BCardText,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral, VeeValidateLoadLocale],
  props: {
    openModalAngariacao: {
      type: Function,
      required: true,
    },
    visitaEditMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      search: [],
      langCalendar: {},
      pidAngariacao: null,
      loadingAgenciasFlag: false,
      loadingAgentesFlag: false,
      isLoadingLeads: false,
      listLeadsApp: [],
    }
  },
  computed: {
    ...mapGetters('crm_visitas_form', ['arrayAngariacao', 'form_data', 'arraySW128', 'arraySW172', 'arraySW025', 'flagAngariacaoExternaSaved', 'flagAngariacaoExternaAdded', 'arrayConsultoresTeam', 'flagShowConcluir']),
  },
  async mounted() {
    await this.importLocale(this.$i18n.locale).then(res => {
      this.langCalendar = res.default
    })
  },
  methods: {
    ...mapActions('crm_visitas_form', ['setOptionSelectLead']),
    async setListLeads() {
      await this.$store.dispatch('crm_visitas_form/setListLeads').catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async importLocale(lang) {
      return import(`vue2-datepicker/locale/${lang}`)
    },
    async showMsgErrorRequest(error) {
      if (useCapacitor.isNative() === true) {
        Dialog.alert({
          title: this.$t('Alerta'),
          message: error.message,
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Alerta'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      }
    },
    async showMsgSuccessRequest(info) {
      if (info.message) {
        await this.$swal({
          title: '',
          text: info.message,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    listviewFilterOrderBy(field) {
      this.$store.dispatch('share/listviewFilterOrderBy', field).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewIconOrderBy(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.listviewOrderBy.column === filterField) {
        if (this.listviewOrderBy.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
    async getAgenciasList() {
      this.loadingAgenciasFlag = true
      await this.$store.dispatch('crm_visitas_form/loadArray172').catch(error => {
        this.showMsgErrorRequest(error)
      })
      this.loadingAgenciasFlag = false
    },
    async getListAgentes() {
      this.loadingAgentesFlag = true
      await this.$store.dispatch('crm_visitas_form/loadArray025').catch(error => {
        this.showMsgErrorRequest(error)
      })
      this.loadingAgentesFlag = false
    },
    onSearchLeadsAppSelect(search, loading) {
      if (search.length) {
        loading(true)
        this.searchLeadsAppSelect(loading, search, this)
      }
    },
    searchLeadsAppSelect: debounce((loading, search, vm) => {
      const oThis = vm

      vm.$store.dispatch('crm_visitas_form/searchLeadsByUserAuthEncode', search).then(res => {
        oThis.listLeadsApp = res
        loading(false)
      }).catch(error => {
        vm.showMsgErrorRequest(error)
        oThis.listLeadsApp = []
        loading(false)
      })
    }, 500),
    checkConcluirAction() {
      // if (this.form_data.sw070s10 !== undefined && this.form_data.sw070s10 !== null && this.form_data.sw070s10_time !== undefined && this.form_data.sw070s10_time !== null) {
      //   const dateedit = `${this.form_data.sw070s10} ${this.form_data.sw070s10_time}`

      //   let dataAtual = new Date().toISOString()
      //   dataAtual = dataAtual.replace('T', ' ', dataAtual)
      //   dataAtual = dataAtual.substring(0, 19)
      //   dataAtual = format(parseISO(dataAtual), 'yyyy-MM-dd HH:mm')
      //   const dataEvento = format(parseISO(dateedit), 'yyyy-MM-dd HH:mm')
      //   const result = isAfter(parseISO(dataAtual), parseISO(dataEvento))

      //   this.$store.dispatch('crm_visitas_form/setFlagShowConcluir', result).catch(error => {
      //     this.showMsgErrorRequest(error)
      //   })
      // }
    },
  },
  setup() {
    return {
      avatarText,
      configSettingsScroll,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.email-app-details .email-scroll-area {
  height: calc( 100% - calc(4.9rem) )!important;
}

.email-app-details {
  width: 100%!important;
  z-index: 3!important;
}
</style>
