<template>
  <div style="height: inherit">

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <div class="email-app-list position-relative space-bottom">

      <!-- Barra pesquisa / ordenação -->
      <div class="app-fixed-search d-flex align-items-center">
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            :size="sizeIconsAction"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchTxt"
              value=""
              :placeholder="listview_txt_default.txt_search"
            />
          </b-input-group>
        </div>

        <div
          v-if="fieldsOrder.length > 0 && (filterSelected.separadorSelecionado !== 3 && filterSelected.separadorSelecionado !== 1)"
          class="dropdown"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                :size="sizeIconsAction"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-for="oField in fieldsOrder"
              :key="`field-order-${oField.name}`"
              @click="listviewFilterOrderBy(oField.name)"
            >
              <feather-icon
                :icon="listviewIconOrderBy(oField.name)"
                :class="listviewIconOrderBy(oField.name, true)"
              />
              {{ oField.txt }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div
          v-if="fieldsOrderConcluidos.length > 0 && (filterSelected.separadorSelecionado === 3 || filterSelected.separadorSelecionado === 1)"
          class="dropdown"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                :size="sizeIconsAction"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-for="oField in fieldsOrderConcluidos"
              :key="`field-order-${oField.name}`"
              @click="listviewFilterOrderBy(oField.name)"
            >
              <feather-icon
                :icon="listviewIconOrderBy(oField.name)"
                :class="listviewIconOrderBy(oField.name, true)"
              />
              {{ oField.txt }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

      </div>

      <!-- Listagem -->
      <div class="email-user-list scroll-area">
        <vue-pull-refresh
          ref="listviewScroll"
          :on-refresh="listViewRefresh"
          :config="{
            errorLabel: $t('Problema ao atualizar'),
            startLabel: $t('Baixar para atualizar'),
            readyLabel: $t('Solte para atualizar'),
            loadingLabel: $t('A atualizar...'),
            pullDownHeight: 60,
          }"
        >
          <listview-list-data
            v-if="listviewData.length > 0"
            ref="list"
            :open-modal-detail-by-row="openModalDetailByRow"
          />
          <div
            class="no-results"
            :class="{'show': !listviewData.length}"
          >
            <h5 v-if="(filterSelectedTotalFields === 0) && !listviewData.length && (listviewInit === 0)">
              {{ listview_txt_default.txt }}
            </h5>
            <h5 v-if="(filterSelectedTotalFields > 0) && !listviewData.length && (listviewInit === 0)">
              {{ listview_txt_default.txt_not_find }}
            </h5>
            <h5 v-if="listviewInit === 1">
              {{ listview_txt_default.txt_loading }}
            </h5>
          </div>
        </vue-pull-refresh>
      </div>

      <b-container
        v-if="listviewData.length > 0"
        fluid
      >
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="mb-0 pagination-white pt-0_2 pb-0_2"
              :size="sizePaginationListviewResponsive"
            />
          </b-col>
        </b-row>
      </b-container>

      <b-overlay
        :show="$store.getters['crm_visitas_listview/loadingListview']"
        no-wrap
      />

    </div>

    <portal to="content-renderer-sidebar-left">
      <listview-left-sidebar
        ref="leftSidebar"
        :class="{'show': mqShallShowLeftSidebar}"
        :show-msg-error-request="showMsgErrorRequest"
        :show-concluir-action="showConcluirAction"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
        @open-nova-visita="setShowSimpleFormVisita(true)"
        @open-nova-visita-externa="optionsVisitaExterna()"
        @cancel-nova-visita="cancelVisitaForm(true)"
        @save-nova-visita="saveVisitaForm"
        @save-nova-visita-lead-externa="saveVisitaFormLeadExterna"
        @save-nova-visita-angariacao-externa="saveVisitaFormAngariacaoExterna"
        @concluir-nova-visita="concluirSaveVisitaForm"
        @concluir-nova-visita-lead-externa="concluirSaveVisitaFormLeadExterna"
        @concluir-nova-visita-angariacao-externa="concluirSaveVisitaFormAngariacaoExterna"
      />
    </portal>

    <processo-modal-detail
      v-if="showProcessoModalDetail===true"
      ref="processoModalDetail"
      :row="rowModalAction"
      :separador="filterSelected.separadorSelecionado"
      :get-modal-report="getModalReport"
      :show-msg-error-request="showMsgErrorRequest"
      :show-msg-success-request="showMsgSuccessRequest"
      @edit-modal-visita="editModalVisita"
      @edit-modal-visita-lead-externa="editModalVisitaLeadExterna"
      @edit-modal-visita-angariacao-externa="editModalVisitaAngariacaoExterna"
    />

    <component
      :is="ModalReportVisita"
      ref="modalReportVisita"
      :visita="filterSelected.visitaSelecionado"
      :row="rowModalAction"
      :show-msg-error-request="showMsgErrorRequest"
      :show-msg-success-request="showMsgSuccessRequest"
      :get-modal-report="getModalReport"
    />

    <component
      :is="ModalCancelVisita"
      ref="modalCancelVisita"
      :visita="filterSelected.visitaSelecionado"
      :show-msg-error-request="showMsgErrorRequest"
      :show-msg-success-request="showMsgSuccessRequest"
    />

    <component
      :is="ModalRejeitarVisita"
      ref="modalRejeitarVisita"
      :visita="filterSelected.visitaSelecionado"
      :show-msg-error-request="showMsgErrorRequest"
      :show-msg-success-request="showMsgSuccessRequest"
    />

    <simple-form
      ref="modalNovaVisita"
      :class="{'show' : showSimpleFormVisita}"
      :open-modal-angariacao="openModalAngariacao"
      :show-concluir-action="showConcluirAction"
      :visita-edit-mode="visitaEditMode"
      @cancel-nova-visita="cancelVisitaForm(true)"
      @save-nova-visita="saveVisitaForm"
      @concluir-nova-visita="concluirSaveVisitaForm"
    />

    <simple-form-angariacao-externa
      ref="modalNovaVisitaAngariacaoExterna"
      :class="{'show' : showSimpleFormAngariacaoExternaVisita}"
      :open-modal-angariacao="openModalAngariacao"
      :visita-edit-mode="visitaEditMode"
      @form-nova-angariacao-externa="setShowFormNovaAngariacaoExterna(true)"
      @cancel-nova-visita="cancelVisitaForm(true)"
      @save-nova-visita="saveVisitaFormAngariacaoExterna"
      @concluir-nova-visita="concluirSaveVisitaFormAngariacaoExterna"
      @form-novo-agente="setShowFormNovoAgente(true)"
      @form-nova-agencia="setShowFormNovaAgencia(true)"
    />

    <simple-form-lead-externa
      ref="modalNovaVisitaLeadExterna"
      :class="{'show' : showSimpleFormLeadExternaVisita}"
      :open-modal-angariacao="openModalAngariacao"
      :visita-edit-mode="visitaEditMode"
      @cancel-nova-visita="cancelVisitaForm(true)"
      @save-nova-visita="saveVisitaFormLeadExterna"
      @concluir-nova-visita="concluirSaveVisitaFormLeadExterna"
      @form-novo-agente="setShowFormNovoAgente(true)"
      @form-nova-agencia="setShowFormNovaAgencia(true)"
    />

    <form-nova-angariacao-externa
      v-if="showFormNovaAngariacaoExterna===true"
      ref="formNovaAngariacaoExterna"
      :show-msg-error-request="showMsgErrorRequest"
      :show-msg-success-request="showMsgSuccessRequest"
    />

    <form-novo-agente
      v-if="showFormNovoAgente===true"
      ref="modalNovoAgente"
      :show-msg-error-request="showMsgErrorRequest"
      :show-msg-success-request="showMsgSuccessRequest"
      @save-novo-agente="saveNovoAgente"
      @cancel-novo-agente="cancelNovoAgente"
    />

    <form-nova-agencia
      v-if="showFormNovaAgencia===true"
      ref="modalNovaAgencia"
      :show-msg-error-request="showMsgErrorRequest"
      :show-msg-success-request="showMsgSuccessRequest"
      @save-nova-agencia="saveNovaAgencia"
      @cancel-nova-agencia="cancelNovaAgencia"
    />

    <form-adiciona-angariacao
      v-if="addModalAngariacao===true"
      :class="{'show': showModalAngariacao}"
      :save-form-angariacao="saveFormAngariacao"
      :show-msg-error-request="showMsgErrorRequest"
      :only-external="flagShowAngariacaoExterna"
      @close-email-view="closeModalAngariacao"
    />

  </div>
</template>

<script>
import store from '@/store'
import '@/libs/sweet-alerts'
import { onUnmounted } from '@vue/composition-api'
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BContainer,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import crmModule from '@store-modules/crm/visitas/listview'
import crmModuleForm from '@store-modules/crm/visitas/form/index'
import modelSW001 from '@store-modules/users/data'
import { sizeIconsAction, showMsgRequest, VeeValidateLoadLocale } from '@core-custom/mixins/geral'
import modelSW025 from '@store-modules/crm/leads/data'
import i18n from '@/libs/i18n'
import VuePullRefresh from 'vue-pull-refresh'
import { useRouter, showModalMessage } from '@core/utils/utils'
import processoModalDetail from './modalDetail.vue'
import ListviewLeftSidebar from './leftSidebar.vue'
import ListviewListData from './listData.vue'
import simpleForm from '../form/form.vue'
import simpleFormAngariacaoExterna from '../form/formAngariacaoExterna.vue'
import simpleFormLeadExterna from '../form/formLeadExterna.vue'
import formNovaAngariacaoExterna from '../form/modalNovaAngariacaoExterna.vue'
import formNovoAgente from '../form/modalNovoAgente.vue'
import formNovaAgencia from '../form/modalNovaAgencia.vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BContainer,
    BPagination,
    BOverlay,
    ListviewLeftSidebar,
    ListviewListData,
    processoModalDetail,
    simpleForm,
    simpleFormAngariacaoExterna,
    simpleFormLeadExterna,
    formNovaAngariacaoExterna,
    formNovoAgente,
    formNovaAgencia,
    VuePullRefresh,
    FormAdicionaAngariacao: () => import(/* webpackChunkName: "crm-visitas-angariacao-form" */ /* webpackPrefetch: true */ '../form/angariacao.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction, showMsgRequest, VeeValidateLoadLocale],
  props: {
    createNew: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showProcessoModalDetail: false,
      showFormNovaAngariacaoExterna: false,
      showFormNovoAgente: false,
      showFormNovaAgencia: false,
      showAngariacoesExternas: false,
      rowModalAction: {},
      showConcluirAction: false,
      visitaEditMode: false,
      formAgenciaError: 0,
    }
  },
  computed: {
    ...mapGetters('crm_visitas_listview', ['listviewData', 'formData070', 'formData073', 'filterData', 'filterSelected', 'listviewOrderBy', 'totalRows', 'perPage', 'listview_txt_default', 'listviewInit', 'fieldsOrder', 'fieldsOrderConcluidos', 'filterSelectedTotalFields', 'positionListview']),
    ...mapGetters('crm_visitas_form', ['arrayAngariacao', 'form_data', 'arraySW175', 'arraySW175_index', 'showSimpleFormVisita', 'showSimpleFormAngariacaoExternaVisita', 'showSimpleFormLeadExternaVisita', 'showExternalFormVisita', 'showExternalLeadFormVisita', 'showModalAngariacao', 'addModalAngariacao', 'flagAngariacaoExternaSaved', 'flagShowAngariacaoExterna']),
    sizePaginationListviewResponsive() {
      const currentBreakPoint = this.$store.getters['app/currentBreakPoint']
      return ((currentBreakPoint === 'xs') ? 'lg' : 'md')
    },
    currentPage: {
      get() {
        return this.$store.getters['crm_visitas_listview/currentPage']
      },
      set(newValue) {
        this.$store.dispatch('crm_visitas_listview/setCurrentPage', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    searchTxt: {
      get() {
        return this.$store.getters['crm_visitas_listview/searchTxt']
      },
      set(newValue) {
        this.$store.dispatch('crm_visitas_listview/setSearchTxt', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
  },
  watch: {
    $route() {
      this.$refs.leftSidebar.$refs.filterScroll.$el.scrollTop = 0
    },
    '$route.query': {
      immediate: false,
      handler(newVal) {
        this.loadFilterCustomQuery(newVal, true)
      },
    },
  },
  beforeDestroy() {
    this.$store.commit('crm_visitas_listview/savePositionListview', this.sPostionListview)
    this.$store.commit('crm_visitas_listview/saveStates')
  },
  async created() {
    try {
      this.$store.commit('crm_visitas_listview/setHistoryKeyStorage', this.$route.name)

      await store.dispatch('crm_visitas_listview/getDataFiltersListview', { pageSel: this.$route.name }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      await this.loadFilterCustomQuery(this.routeParamsQuery, true)

      await store.dispatch('crm_visitas_listview/fetchListView', { skipCurrentPage: true }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      await this.$nextTick()

      if ((this.positionListview !== null) && (this.positionListview !== undefined)) {
        const { scrollTop, scrollLeft } = this.positionListview
        this.$refs.listviewScroll.$el.scrollTop = scrollTop
        this.$refs.listviewScroll.$el.scrollLeft = scrollLeft
      }
    } catch (err) {
      //
    }

    if (this.createNew === true) {
      this.setShowSimpleFormVisita(true)
    }
  },
  methods: {
    setShowFormNovaAngariacaoExterna(newValue) {
      this.showFormNovaAngariacaoExterna = newValue
    },
    setShowFormNovoAgente(newValue) {
      if (newValue === true && (this.form_data === undefined || this.form_data.sw070s21 === undefined || this.form_data.sw070s41 === undefined || this.form_data.sw070s21 === null || this.form_data.sw070s41 === null || this.form_data.sw070s21 === '' || this.form_data.sw070s41 === '')) {
        this.showMsgErrorRequest(new Error(i18n.t('Selecione a rede imobiliária e agência')))
      } else {
        this.showFormNovoAgente = newValue
      }
    },

    setShowFormNovaAgencia(newValue) {
      this.showFormNovaAgencia = newValue
    },
    listviewIconOrderBy(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.listviewOrderBy.column === filterField) {
        if (this.listviewOrderBy.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
    async listViewRefresh() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('crm_visitas_listview/pullToRefreshListview')
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    listviewFilterOrderBy(field) {
      this.$store.dispatch('crm_visitas_listview/listviewFilterOrderBy', field).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    openModalDetailByRow(row) {
      this.showProcessoModalDetail = true

      if (row !== undefined && row !== null) {
        this.rowModalAction = row || {}
      } else {
        this.$refs.processoModalDetail.getModalData()
      }
    },
    async getModalReport(type) {
      this.$root.$emit('app::loading', true)

      if (type !== null && type === 'load') {
        this.$refs.modalReportVisita.loadData()
      } else {
        this.$refs.modalReportVisita.resetData()
      }

      if (type !== null && type === 'shortcut') {
        this.$refs.modalReportVisita.flagProposta = true
      }

      await this.$refs.modalReportVisita.showModal().then(result => {
        if (result === true) {
          this.$root.$emit('app::loading', false)
        }
      })
    },
    async getCancelModal() {
      this.$root.$emit('app::loading', true)
      await this.$refs.modalCancelVisita.showModal().then(result => {
        if (result === true) {
          this.$root.$emit('app::loading', false)
        }
      })
    },
    async getRejeitarModal() {
      this.$root.$emit('app::loading', true)
      await this.$refs.modalRejeitarVisita.showModal().then(result => {
        if (result === true) {
          this.$root.$emit('app::loading', false)
        }
      })
    },
    async setShowSimpleFormVisita(newValue, editMode) {
      this.$root.$emit('app::loading', true)

      this.visitaEditMode = false

      if (newValue === false) {
        await this.$store.dispatch('crm_visitas_form/setShowModalAngariacao', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })

        await this.$store.dispatch('crm_visitas_form/setShowSimpleFormVisita', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })

        this.$root.$emit('app::loading', false)
      } else {
        if (editMode) {
          this.visitaEditMode = true
        }

        await this.$store.dispatch('crm_visitas_form/loadArray128').then(res => {
          if (res === 1) {
            this.$store.dispatch('crm_visitas_form/setShowSimpleFormVisita', newValue).catch(error => {
              this.showMsgErrorRequest(error)
            })
            this.$root.$emit('app::loading', false)
          }
        }).catch(error => {
          this.showMsgErrorRequest(error)
          this.$root.$emit('app::loading', false)
        })
      }
    },
    async setShowSimpleFormAngariacaoExternaVisita(newValue, editMode) {
      this.visitaEditMode = false

      if (newValue === false) {
        await this.$store.dispatch('crm_visitas_form/setShowModalAngariacao', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      } else if (editMode) {
        this.visitaEditMode = true
      }

      await this.$store.dispatch('crm_visitas_form/setShowSimpleFormAngariacaoExternaVisita', newValue).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async setShowSimpleFormLeadExternaVisita(newValue, editMode) {
      this.visitaEditMode = false

      if (newValue === false) {
        await this.$store.dispatch('crm_visitas_form/setShowModalAngariacao', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      } else if (editMode) {
        this.visitaEditMode = true
      }

      await this.$store.dispatch('crm_visitas_form/setShowSimpleFormLeadExternaVisita', newValue).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async optionsVisitaExterna() {
      this.$swal({
        title: '',
        html: `${this.$t('Pretendo agendar uma visita para')}:`,
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: this.$t('Cancelar'),
        denyButtonText: this.$t('Angariação externa com lead meu'),
        confirmButtonText: this.$t('Angariação minha com consultor externo'),
        customClass: {
          cancelButton: 'btn w-100 btn-outline-secondary mt-1',
          denyButton: 'btn w-100 btn-outline-warning mt-1',
          confirmButton: 'btn w-100 btn-outline-primary',
        },
        buttonsStyling: false,
        width: '40rem',
      }).then(async result => {
        if (result.isConfirmed === true) {
          this.$root.$emit('app::loading', true)
          await this.$store.dispatch('crm_visitas_form/loadArray128', 1).then(res => {
            if (res === 1) {
              this.setShowSimpleFormLeadExternaVisita(true)
              this.$root.$emit('app::loading', false)
            }
          }).catch(error => {
            this.showMsgErrorRequest(error)
          })
        } else if (result.isDenied === true) {
          this.$root.$emit('app::loading', true)
          await this.$store.dispatch('crm_visitas_form/loadArray128', 2).then(res => {
            if (res === 1) {
              this.setShowSimpleFormAngariacaoExternaVisita(true)
              this.$root.$emit('app::loading', false)
            }
          }).catch(error => {
            this.showMsgErrorRequest(error)
          })
        }
      })
    },
    async saveVisitaForm(flagConcluir) {
      this.$root.$emit('app::loading', true)

      await this.$refs.modalNovaVisita.$refs.formNovaVisita.validate().then(success => {
        if (success) {
          if (this.arrayAngariacao.id === undefined || this.arrayAngariacao.id == null) {
            this.showMsgErrorRequest({ message: this.$t('Adicione uma angariação à visita') })
            this.$root.$emit('app::loading', false)
          } else {
            this.$store.dispatch('crm_visitas_form/saveVisitaForm').then(response => {
              // console.log(response)
              if (response.error !== 1) {
                if (flagConcluir !== undefined && flagConcluir === true) {
                  this.$swal({
                    title: '',
                    html: '',
                    showCancelButton: true,
                    showDenyButton: true,
                    cancelButtonText: this.$t('Cancelar'),
                    denyButtonText: this.$t('Concluir com relatório'),
                    confirmButtonText: this.$t('Concluir sem relatório'),
                    customClass: {
                      cancelButton: 'btn w-100 btn-outline-secondary mt-1',
                      denyButton: 'btn w-100 btn-outline-warning mt-1',
                      confirmButton: 'btn w-100 btn-outline-primary',
                    },
                    buttonsStyling: false,
                    width: '40rem',
                  }).then(async result => {
                    if (result.isConfirmed === true) {
                      this.$root.$emit('app::loading', true)

                      await this.$store.dispatch('crm_visitas_listview/concluirSemRelatorio', response.idVisita).then(res => {
                        if (res.error === 1) {
                          this.showMsgErrorRequest(new Error(res.msg))
                          this.$root.$emit('app::loading', false)
                        } else {
                          this.loadShortcutPropostas(response.idVisita, res.msg)
                        }
                      }).catch(error => {
                        this.showMsgErrorRequest(error)
                      })
                      this.cancelVisitaForm()
                    } else if (result.isDenied === true) {
                      this.rowModalAction = { id_visita: response.idVisita, data_visita: response.data_visita }
                      this.getModalReport('shortcut')
                      this.cancelVisitaForm()
                    } else {
                      this.showMsgSuccessRequest({ message: response.msg })

                      this.setShowSimpleFormVisita(false)
                      this.setShowSimpleFormAngariacaoExternaVisita(false)
                      this.setShowSimpleFormLeadExternaVisita(false)

                      this.cancelVisitaForm()

                      this.$store.dispatch('crm_visitas_listview/fetchListView').catch(error => {
                        this.showMsgErrorRequest(error)
                      })
                    }
                  })
                } else {
                  this.showMsgSuccessRequest({ message: response.msg })

                  this.cancelVisitaForm()

                  this.setShowSimpleFormVisita(false)
                  this.setShowSimpleFormAngariacaoExternaVisita(false)
                  this.setShowSimpleFormLeadExternaVisita(false)

                  this.$store.dispatch('crm_visitas_listview/fetchListView').catch(error => {
                    this.showMsgErrorRequest(error)
                  })
                }
              } else {
                this.showMsgErrorRequest(new Error(response.msg))
              }
              this.$root.$emit('app::loading', false)
            }).catch(error => {
              this.$root.$emit('app::loading', false)
              this.showMsgErrorRequest(error)
            })
          }
        } else {
          this.$root.$emit('app::loading', false)
          this.showMsgErrorRequest({ message: this.$t('Preencha os campos obrigatórios') })
        }
      })
    },
    async concluirSaveVisitaForm() {
      this.$root.$emit('app::loading', true)

      await this.$refs.modalNovaVisita.$refs.formNovaVisita.validate().then(success => {
        if (success) {
          if (this.arrayAngariacao.id === undefined || this.arrayAngariacao.id == null) {
            this.showMsgErrorRequest({ message: this.$t('Adicione uma angariação à visita') })
            this.$root.$emit('app::loading', false)
          } else {
            this.saveVisitaForm(true)
          }
        } else {
          this.$root.$emit('app::loading', false)
          this.showMsgErrorRequest({ message: this.$t('Preencha os campos obrigatórios') })
        }
      })
    },
    async concluirSaveVisitaFormLeadExterna() {
      this.$root.$emit('app::loading', true)

      await this.$refs.modalNovaVisitaLeadExterna.$refs.formNovaVisitaLeadExterna.validate().then(success => {
        if (success) {
          if (this.arrayAngariacao.id === undefined || this.arrayAngariacao.id == null) {
            this.showMsgErrorRequest({ message: this.$t('Adicione uma angariação à visita') })
            this.$root.$emit('app::loading', false)
          } else {
            this.saveVisitaFormLeadExterna(true)
          }
        } else {
          this.$root.$emit('app::loading', false)
          this.showMsgErrorRequest({ message: this.$t('Preencha os campos obrigatórios') })
        }
      })
    },
    async concluirSaveVisitaFormAngariacaoExterna() {
      this.$root.$emit('app::loading', true)

      await this.$refs.modalNovaVisitaAngariacaoExterna.$refs.formNovaVisitaAngariacaoExterna.validate().then(success => {
        if (success) {
          if ((this.arrayAngariacao.id === undefined || this.arrayAngariacao.id == null) && this.flagAngariacaoExternaSaved === false) {
            this.showMsgErrorRequest({ message: this.$t('Adicione uma angariação à visita') })
            this.$root.$emit('app::loading', false)
          } else {
            this.saveVisitaFormAngariacaoExterna(true)
          }
        } else {
          this.$root.$emit('app::loading', false)
          this.showMsgErrorRequest({ message: this.$t('Preencha os campos obrigatórios') })
        }
      })
    },
    async cancelVisitaForm(confirmation) {
      if (confirmation === true) {
        const actionModal = new Promise(resolve => {
          showModalMessage(resolve)
        })

        await actionModal.then(result => {
          if (result === 'confirm') {
            this.$refs.modalNovaVisita.$refs.formNovaVisita.reset()
            this.$refs.modalNovaVisitaLeadExterna.$refs.formNovaVisitaLeadExterna.reset()
            this.$refs.modalNovaVisitaAngariacaoExterna.$refs.formNovaVisitaAngariacaoExterna.reset()
            this.$refs.modalNovaVisita.listLeadsApp = []
            this.$refs.modalNovaVisitaAngariacaoExterna.listLeadsApp = []

            this.$store.dispatch('crm_visitas_form/resetFormData').catch(error => {
              this.showMsgErrorRequest(error)
            })
            this.setShowSimpleFormVisita(false)
            this.setShowSimpleFormAngariacaoExternaVisita(false)
            this.setShowSimpleFormLeadExternaVisita(false)
          }
        })
      } else {
        this.$refs.modalNovaVisita.$refs.formNovaVisita.reset()
        this.$refs.modalNovaVisitaLeadExterna.$refs.formNovaVisitaLeadExterna.reset()
        this.$refs.modalNovaVisitaAngariacaoExterna.$refs.formNovaVisitaAngariacaoExterna.reset()

        await this.$store.dispatch('crm_visitas_form/resetFormData').catch(error => {
          this.showMsgErrorRequest(error)
        })
        this.setShowSimpleFormVisita(false)
        this.setShowSimpleFormAngariacaoExternaVisita(false)
        this.setShowSimpleFormLeadExternaVisita(false)
      }
    },
    async saveNovoAgente() {
      this.$root.$emit('app::loading', true)

      await this.$refs.modalNovoAgente.$refs.formNovoAgente.validate().then(success => {
        if (success) {
          if ((this.form_data.sw025s05 === undefined || this.form_data.sw025s05 === '' || this.form_data.sw025s05 === null) && (this.form_data.sw025s06 === undefined || this.form_data.sw025s06 === null || this.form_data.sw025s06 === '')) {
            this.showMsgErrorRequest({ message: this.$t('É obrigatório ter um telefone ou email') })
            this.$root.$emit('app::loading', false)
          } else {
            this.$store.dispatch('crm_visitas_form/saveAgenteForm').then(response => {
              if (response.error !== 1) {
                this.showMsgSuccessRequest({ message: response.msg })
                this.setShowFormNovoAgente(false)
              } else {
                this.showMsgErrorRequest(new Error(response.msg))
              }
              this.$root.$emit('app::loading', false)
            }).catch(error => {
              this.$root.$emit('app::loading', false)
              this.showMsgErrorRequest(error)
            })
          }
        } else {
          this.$root.$emit('app::loading', false)
          this.showMsgErrorRequest({ message: this.$t('Preencha os campos obrigatórios') })
        }
      })
    },
    async saveNovaAgencia() {
      this.$root.$emit('app::loading', true)
      this.formAgenciaError = 0

      if ((this.form_data.arrayAgencia172.sw172s26_txt === undefined || this.form_data.arrayAgencia172.sw172s26_txt === '') && this.form_data.sw172s26 === undefined) {
        this.showMsgErrorRequest({ message: this.$t('Preencha a rede imobiliária') })
        this.$root.$emit('app::loading', false)
        this.formAgenciaError += 1
      }

      if (this.form_data.sw175s03_sel !== undefined && this.form_data.sw175s03_sel !== null && this.form_data.sw175s03_sel >= this.arraySW175_index) {
        this.arraySW175.forEach((value, index) => {
          if (index === this.form_data.sw175s03_sel && (value.sw175s03 === undefined || value.sw175s03 === '' || value.sw175s03 === null)) {
            this.showMsgErrorRequest({ message: this.$t('Preencha o nome da agência') })
            this.$root.$emit('app::loading', false)
            this.formAgenciaError += 1
          }
        })
      }

      if (this.formAgenciaError === 0) {
        this.$store.dispatch('crm_visitas_form/saveAgenciaForm').then(response => {
          if (response.error !== 1) {
            if (response.agenciaSelected !== null) {
              this.$store.dispatch('crm_visitas_form/loadArray172', response.agenciaSelected).catch(error => {
                this.showMsgErrorRequest(error)
              })
            }
            this.showMsgSuccessRequest({ message: response.msg })
            this.cancelNovaAgencia()
          } else {
            this.showMsgErrorRequest(new Error(response.msg))
          }
          this.$root.$emit('app::loading', false)
        }).catch(error => {
          this.$root.$emit('app::loading', false)
          this.showMsgErrorRequest(error)
        })
      }
    },
    async cancelNovoAgente() {
      await this.$store.dispatch('crm_visitas_form/cancelNovoAgente').catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async cancelNovaAgencia() {
      this.setShowFormNovaAgencia(false)
      await this.$store.dispatch('crm_visitas_form/cancelNovaAgencia').catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async saveVisitaFormLeadExterna(flagConcluir) {
      this.$root.$emit('app::loading', true)

      await this.$refs.modalNovaVisitaLeadExterna.$refs.formNovaVisitaLeadExterna.validate().then(success => {
        if (success) {
          if (this.arrayAngariacao.id === undefined || this.arrayAngariacao.id == null) {
            this.showMsgErrorRequest({ message: this.$t('Adicione uma angariação à visita') })
            this.$root.$emit('app::loading', false)
          } else {
            this.$store.dispatch('crm_visitas_form/saveVisitaFormLeadExterna').then(response => {
              if (response.error !== 1) {
                if (flagConcluir !== undefined && flagConcluir === true) {
                  this.$swal({
                    title: '',
                    html: '',
                    showCancelButton: true,
                    showDenyButton: true,
                    cancelButtonText: this.$t('Cancelar'),
                    denyButtonText: this.$t('Concluir com relatório'),
                    confirmButtonText: this.$t('Concluir sem relatório'),
                    customClass: {
                      cancelButton: 'btn w-100 btn-outline-secondary mt-1',
                      denyButton: 'btn w-100 btn-outline-warning mt-1',
                      confirmButton: 'btn w-100 btn-outline-primary',
                    },
                    buttonsStyling: false,
                    width: '40rem',
                  }).then(async result => {
                    if (result.isConfirmed === true) {
                      this.$root.$emit('app::loading', true)

                      await this.$store.dispatch('crm_visitas_listview/concluirSemRelatorio', response.idVisita).then(res => {
                        if (res.error === 1) {
                          this.showMsgErrorRequest(new Error(res.msg))
                        } else {
                          this.loadShortcutPropostas(response.idVisita, res.msg)
                        }

                        this.$root.$emit('app::loading', false)
                      }).catch(error => {
                        this.showMsgErrorRequest(error)
                      })
                      this.cancelVisitaForm()
                    } else if (result.isDenied === true) {
                      this.rowModalAction = { id_visita: response.idVisita, data_visita: response.data_visita }
                      this.getModalReport('shortcut')
                      this.cancelVisitaForm()
                    } else {
                      this.showMsgSuccessRequest({ message: response.msg })

                      this.cancelVisitaForm()

                      this.$store.dispatch('crm_visitas_listview/fetchListView').catch(error => {
                        this.showMsgErrorRequest(error)
                      })
                    }
                  })
                } else {
                  this.showMsgSuccessRequest({ message: response.msg })

                  this.cancelVisitaForm()

                  this.$store.dispatch('crm_visitas_listview/fetchListView').catch(error => {
                    this.showMsgErrorRequest(error)
                  })
                }
              } else {
                this.showMsgErrorRequest(new Error(response.msg))
              }
              this.$root.$emit('app::loading', false)
            }).catch(error => {
              this.$root.$emit('app::loading', false)
              this.showMsgErrorRequest(error)
            })
          }
        } else {
          this.$root.$emit('app::loading', false)
          this.showMsgErrorRequest({ message: this.$t('Preencha os campos obrigatórios') })
        }
      })
    },
    async saveVisitaFormAngariacaoExterna(flagConcluir) {
      this.$root.$emit('app::loading', true)

      await this.$refs.modalNovaVisitaAngariacaoExterna.$refs.formNovaVisitaAngariacaoExterna.validate().then(success => {
        if (success) {
          if ((this.arrayAngariacao.id === undefined || this.arrayAngariacao.id == null) && this.flagAngariacaoExternaSaved === false) {
            this.showMsgErrorRequest({ message: this.$t('Adicione uma angariação à visita') })
            this.$root.$emit('app::loading', false)
          } else {
            this.$store.dispatch('crm_visitas_form/saveVisitaFormAngariacaoExterna').then(response => {
              // console.log(response)
              if (response.error !== 1) {
                if (flagConcluir !== undefined && flagConcluir === true) {
                  this.$swal({
                    title: '',
                    html: '',
                    showCancelButton: true,
                    showDenyButton: true,
                    cancelButtonText: this.$t('Cancelar'),
                    denyButtonText: this.$t('Concluir com relatório'),
                    confirmButtonText: this.$t('Concluir sem relatório'),
                    customClass: {
                      cancelButton: 'btn w-100 btn-outline-secondary mt-1',
                      denyButton: 'btn w-100 btn-outline-warning mt-1',
                      confirmButton: 'btn w-100 btn-outline-primary',
                    },
                    buttonsStyling: false,
                    width: '40rem',
                  }).then(async result => {
                    if (result.isConfirmed === true) {
                      this.$root.$emit('app::loading', true)

                      await this.$store.dispatch('crm_visitas_listview/concluirSemRelatorio', response.idVisita).then(res => {
                        if (res.error === 1) {
                          this.showMsgErrorRequest(new Error(res.msg))
                        } else {
                          this.loadShortcutPropostas(response.idVisita, res.msg)
                        }
                      }).catch(error => {
                        this.showMsgErrorRequest(error)
                      })
                      this.cancelVisitaForm()
                    } else if (result.isDenied === true) {
                      this.rowModalAction = { id_visita: response.idVisita, data_visita: response.data_visita }
                      this.getModalReport('shortcut')
                      this.cancelVisitaForm()
                    } else {
                      this.showMsgSuccessRequest({ message: response.msg })

                      this.cancelVisitaForm()

                      this.$store.dispatch('crm_visitas_listview/fetchListView').catch(error => {
                        this.showMsgErrorRequest(error)
                      })
                    }
                  })
                } else {
                  this.showMsgSuccessRequest({ message: response.msg })

                  this.cancelVisitaForm()

                  this.$store.dispatch('crm_visitas_listview/fetchListView').catch(error => {
                    this.showMsgErrorRequest(error)
                  })
                }
              } else {
                this.showMsgErrorRequest(new Error(response.msg))
              }
              this.$root.$emit('app::loading', false)
            }).catch(error => {
              this.$root.$emit('app::loading', false)
              this.showMsgErrorRequest(error)
            })
          }
        } else {
          this.$root.$emit('app::loading', false)
          this.showMsgErrorRequest({ message: this.$t('Preencha os campos obrigatórios') })
        }
      })
    },
    async saveFormAngariacao(pid) {
      const self = this

      if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
        self.clicked = true

        self.$root.$emit('app::loading', true)

        this.$store.dispatch('crm_visitas_form/saveAngariacao', pid).then(res => {
          self.$root.$emit('app::loading', false)
          self.showMsgSuccessRequest(res)

          this.$store.dispatch('crm_visitas_form/setShowModalAngariacao', false).catch(error => {
            this.showMsgErrorRequest(error)
          })
        }).catch(error => {
          self.$root.$emit('app::loading', false)
          self.showMsgErrorRequest(error)
        })
        setTimeout(() => {
          self.clicked = false
        }, 1000)
      }
    },
    async openModalAngariacao(newValueAngariacoesExternas) {
      await this.$store.dispatch('crm_visitas_form/setflagShowAngariacaoExterna', false).catch(error => {
        this.showMsgErrorRequest(error)
      })

      if (newValueAngariacoesExternas !== undefined && newValueAngariacoesExternas !== null && newValueAngariacoesExternas === true) {
        await this.$store.dispatch('crm_visitas_form/setflagShowAngariacaoExterna', newValueAngariacoesExternas).catch(error => {
          this.showMsgErrorRequest(error)
        })
      }

      await this.$store.dispatch('crm_visitas_form/setShowModalAngariacao', true).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async closeModalAngariacao() {
      await this.$store.dispatch('crm_visitas_form/setShowModalAngariacao', false).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    editModalVisita() {
      this.setShowSimpleFormVisita(true, true)
    },
    editModalVisitaLeadExterna() {
      this.setShowSimpleFormLeadExternaVisita(true, true)
    },
    editModalVisitaAngariacaoExterna() {
      this.setShowSimpleFormAngariacaoExternaVisita(true, true)
    },
    loadShortcutPropostas(idVisita, mensagem) {
      this.$root.$emit('app::loading', false)
      this.$swal({
        title: '',
        html: this.$t('Pretende avançar para a proposta?'),
        showCancelButton: false,
        showDenyButton: true,
        confirmButtonText: this.$t('Não'),
        denyButtonText: this.$t('Sim'),
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          denyButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(async resultP => {
        if (resultP.isDenied === true) {
          this.$root.$emit('app::loading', true)
          this.cancelVisitaForm()
          this.$router.push({ name: 'crm-proposals-new-visita', params: { idvisita: idVisita } })
        } else {
          this.showMsgSuccessRequest({ message: mensagem })

          this.cancelVisitaForm()

          this.$store.dispatch('crm_visitas_listview/fetchListView').catch(error => {
            this.showMsgErrorRequest(error)
          })
          this.$root.$emit('app::loading', false)
        }
      })
    },
    async loadFilterCustomQuery(paramsQuery, openViewDetail) {
      return new Promise(resolve => {
        let filterCustomQuery = false
        let sFindTabSelectFilter = ''
        let sViewDetailID = ''
        if ((paramsQuery !== null) && (paramsQuery !== undefined)) {
          if ('agendadas' in paramsQuery) {
            sFindTabSelectFilter = 2
            filterCustomQuery = true
          }

          if ('canceladas' in paramsQuery) {
            sFindTabSelectFilter = 4
            filterCustomQuery = true
          }

          if ('concluidasSemRelatorio' in paramsQuery) {
            sFindTabSelectFilter = 1
            filterCustomQuery = true
          }

          if ('concluidasComRelatorio' in paramsQuery) {
            sFindTabSelectFilter = 3
            filterCustomQuery = true
          }

          if ((filterCustomQuery === true) && (sFindTabSelectFilter !== '')) {
            const tabSelect = this.filterData.listviewFilterSeparadores.find(tab => tab.key === sFindTabSelectFilter)
            if (tabSelect !== undefined) {
              this.$store.commit('crm_visitas_listview/setTabSelectFilter', tabSelect.key)
            }
          }

          if (('viewDetail' in paramsQuery) && (paramsQuery.viewDetail !== '')) {
            filterCustomQuery = true
            sViewDetailID = paramsQuery.viewDetail
          }
        }

        if (filterCustomQuery === false) {
          this.$store.commit('crm_visitas_listview/initStates')
        } else {
          this.$router.replace({ query: {} }).then(() => {
            if ((openViewDetail === true) && (sViewDetailID !== '')) {
              this.openModalDetailByRow({ id_visita: sViewDetailID })
            }
          })
        }

        resolve({ filterCustomQuery, sViewDetailID })
      })
    },
  },
  setup() {
    const CRM_VISITAS_MODULE_NAME = 'crm_visitas_listview'
    const CRM_VISITAS_FORM_MODULE_NAME = 'crm_visitas_form'
    const MODEL_SW001 = 'sw001'
    const MODEL_SW025 = 'sw025'

    if (!store.hasModule(CRM_VISITAS_MODULE_NAME)) {
      store.registerModule(CRM_VISITAS_MODULE_NAME, crmModule)

      onUnmounted(() => {
        if (store.hasModule(CRM_VISITAS_MODULE_NAME)) store.unregisterModule(CRM_VISITAS_MODULE_NAME)
      })
    }

    if (!store.hasModule(CRM_VISITAS_FORM_MODULE_NAME)) {
      store.registerModule(CRM_VISITAS_FORM_MODULE_NAME, crmModuleForm)

      onUnmounted(() => {
        if (store.hasModule(CRM_VISITAS_FORM_MODULE_NAME)) store.unregisterModule(CRM_VISITAS_FORM_MODULE_NAME)
      })
    }

    if (!store.hasModule(MODEL_SW001)) {
      store.registerModule(MODEL_SW001, modelSW001)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SW001)) store.unregisterModule(MODEL_SW001)
      })
    }

    if (!store.hasModule(MODEL_SW025)) {
      store.registerModule(MODEL_SW025, modelSW025)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SW025)) store.unregisterModule(MODEL_SW025)
      })
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const { route } = useRouter()
    const routeParamsQuery = route.value.query

    const ModalReportVisita = () => import(/* webpackChunkName: "crm-visitas-modal-report" */ /* webpackPrefetch: true */ './modal/report.vue')
    const ModalCancelVisita = () => import(/* webpackChunkName: "crm-visitas-modal-cancel" */ /* webpackPrefetch: true */ './modal/cancelVisita.vue')
    const ModalRejeitarVisita = () => import(/* webpackChunkName: "crm-visitas-modal-cancel" */ /* webpackPrefetch: true */ './modal/rejeitarVisita.vue')
    return {
      mqShallShowLeftSidebar,
      ModalReportVisita,
      ModalCancelVisita,
      routeParamsQuery,
      ModalRejeitarVisita,
    }
  },
}
</script>

<style lang="scss" scoped>
/* Desativa as animação devido que provoca erro no scroll to refresh */
.capacitor {
  .email-user-list ::v-deep {
    .email-media-list li {
      animation: none !important;
      animation-fill-mode: none !important;
    }
  }
}

.email-application .content-area-wrapper .sidebar .email-app-sidebar .email-app-menu.sws-not-button-create .sidebar-menu-list {
  height: calc(100%);
}

.force-padding-left-05 {
  padding-left: 0.5rem !important;
}

.force-padding-0 {
  padding: 0 !important;
}

.pull-down-container ::v-deep {
  .pull-down-header {
    background-color: inherit !important;
  }

  .pull-down-content {
    font-family: inherit !important;
    color: inherit !important;
    font-size: inherit !important;
  }
}
</style>
