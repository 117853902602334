<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="closeModal(false)"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ $t('Novo agente externo') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>
        <vue-perfect-scrollbar
          class="b-sidebar-scrollarea-form"
          :settings="configSettingsScroll"
        >
          <b-container
            class="pt-2 pb-0 pl-2 pr-2"
            fluid
          >
            <validation-observer
              ref="formNovoAgente"
              #default="{}"
            >
              <b-row>
                <b-col md="12">
                  <b-form-group
                    :label="$t('Rede imobiliária')"
                  ><p
                    class="text-primary mb-0"
                  >
                    {{ form_data.sw070s21.sw128s02 || '----' }}
                  </p>

                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    :label="$t('Agência')"
                  ><p
                    class="text-primary mb-0"
                  >
                    {{ form_data.sw070s41.designacao_comercial || '----' }}
                  </p>

                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Nome')"
                    >
                      <b-form-input
                        v-model="form_data.sw025s03"
                        :class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                        placeholder=""
                        autocomplete="off"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Apelido')"
                    >
                      <b-form-input
                        v-model="form_data.sw025s04"
                        :class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                        placeholder=""
                        autocomplete="off"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    :rules="{numberVueTelInput: numberPhoneValidate }"
                  >
                    <b-form-group
                      :label="$t('Telefone')"
                    >
                      <vue-tel-input
                        ref="phone"
                        v-model="form_data.sw025s05"
                        class="form-control"
                        inputmode="tel"
                        :mode="configPhone.mode"
                        :valid-characters-only="configPhone.validCharactersOnly"
                        :auto-format="configPhone.autoFormat"
                        :default-country="configPhone.defaultCountry"
                        :auto-default-country="configPhone.autoDefaultCountry"
                        :input-options="configPhone.inputOptions"
                        :dropdown-options="configPhone.dropdownOptions"
                        :class="errors.length > 0 ? 'is-invalid':null"
                        @input="checkValidateNumberPhone"
                        @open="onDropdownOpenTelInput"
                        @keydown.native.tab.prevent="$event.target.blur()"
                        @blur.native.capture.prevent="inputValidateNumberPhone($refs.phone)"
                      >
                        <template slot="arrow-icon">
                          {{ '\xa0' }}
                        </template>
                        <template slot="icon-right">
                          {{ '\xa0' }}
                        </template>
                      </vue-tel-input>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    rules="email"
                  >
                    <b-form-group
                      :label="$t('Email')"
                    >
                      <b-form-input
                        v-model="form_data.sw025s06"
                        placeholder=""
                        autocomplete="off"
                        inputmode="email"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </validation-observer>
          </b-container>

        </vue-perfect-scrollbar>
        <div
          class="d-flex p-1 justify-content-center"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mr-2"
            @click.stop.prevent="closeModal(false)"
          >
            {{ $t('Fechar') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click.stop.prevent="$emit('save-novo-agente')"
          >
            {{ $t('Guardar') }}
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BContainer, BRow, BCol, VBTooltip, BButton, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { mapGetters } from 'vuex'
import { numberVueTelInput } from '@validations-custom'
import { configPhone } from '@core-custom/utils/phone'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { configSettingsScroll } from '@core-custom/utils/ui'
import
{
  isPossiblePhoneNumber,
  validatePhoneNumberLength,
  parsePhoneNumber,
} from 'libphonenumber-js/max'

const oVueTelInput = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input'),
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input/dist/vue-tel-input.css'),
]).then(([{ VueTelInput }]) => VueTelInput)

export default {
  components: {
    BSidebar,
    BContainer,
    BRow,
    BCol,
    BButton,
    VuePerfectScrollbar,
    VueTelInput: oVueTelInput,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction],
  props: {
    showMsgErrorRequest: {
      type: Function,
      required: true,
    },
    showMsgSuccessRequest: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      modalData: [],
      numberVueTelInput,
      configPhone,
    }
  },
  computed: {
    ...mapGetters('crm_visitas_form', ['form_data']),
    numberPhoneValidate() {
      try {
        if (this.form_data.sw025s05) {
          return this.$refs.phone.phoneObject
        }
      } catch (err) {
        //
      }

      return null
    },
  },
  methods: {
    onDropdownOpenTelInput() {
      this.$nextTick(() => {
        const widthElInputTel = this.$refs.phone.$el.offsetWidth
        const el = this.$refs.phone.$refs.list
        el.querySelector('.vti__search_box').placeholder = this.$t('Digite o nome')
        el.querySelector('.vti__search_box').addEventListener('keydown', evt => {
          const charCode = (evt.which) ? evt.which : window.event.keyCode

          if (charCode === 187) {
            evt.preventDefault()
          }
        })

        el.style.width = `${widthElInputTel}px`
      })
    },
    checkValidateNumberPhone($event, phoneObject) {
      this.configPhone.inputOptions.maxlength = 25
      try {
        const phoneNumber = parsePhoneNumber($event, phoneObject?.countryCode || '')
        if (phoneNumber) {
          const sIsPossiblePhoneNumber = isPossiblePhoneNumber(`${$event}0`) // Insert +1 character to simulate
          const sValidatePhoneNumberLength = validatePhoneNumberLength(`${$event}0`) // Insert +1 character to simulate

          if (sValidatePhoneNumberLength === 'TOO_LONG') {
            if (sIsPossiblePhoneNumber === false) {
              this.configPhone.inputOptions.maxlength = $event.length ?? 0
            }
          }
        }
      } catch (error) {
        //
      }
    },
    inputValidateNumberPhone(obj) {
      if (obj?.phoneObject?.number !== undefined && obj.phoneObject.number !== '') {
        try {
          const phoneNumber = parsePhoneNumber(obj.phoneObject.number, obj.phoneObject?.countryCode || '')
          if (phoneNumber) {
            const sIsValidPhoneNumber = phoneNumber.isValid()
            if (sIsValidPhoneNumber === false) {
              this.$nextTick(() => {
                this.$store.dispatch('crm_visitas_form/updateFieldValue', { field: 'sw025s05', value: '' })
              })
            } else {
              this.$nextTick(() => {
                this.$store.dispatch('crm_visitas_form/updateFieldValue', {
                  field: 'sw025s05',
                  value: phoneNumber.formatInternational(),
                })
              })
            }
          }
        } catch (error) {
          //
        }
      } else {
        this.$nextTick(() => {
          this.$store.dispatch('crm_visitas_form/updateFieldValue', { field: 'sw025s05', value: '' })
        })
      }
    },
    closeModal(val) {
      this.$parent.showFormNovoAgente = val
      this.$emit('cancel-novo-agente')
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss">
.vti__search_box {
  width: 100% !important;
}
</style>
