<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="closeModal"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ $t('Agência externa') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>
        <vue-perfect-scrollbar
          class="b-sidebar-scrollarea-form"
          :settings="configSettingsScroll"
        >
          <b-container
            class="pt-2 pb-0 pl-2 pr-2"
            fluid
          >
            <validation-observer
              ref="formNovaAgencia"
              #default="{}"
            >
              <b-row
                v-if="countryApp === 'pt'"
              >
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                  >
                    <b-form-group
                      :label="$t('AMI')"
                    >
                      <b-form-input
                        v-model="form_data.fSearchRedeAgencia_AMI"
                        :class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                        placeholder=""
                        autocomplete="off"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                v-if="countryApp === 'pt'"
              >
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                  >
                    <b-form-group
                      :label="$t('NIPC')"
                    >
                      <b-form-input
                        v-model="form_data.fSearchRedeAgencia_NIF"
                        :class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                        placeholder=""
                        autocomplete="off"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                v-if="countryApp !== 'pt'"
              >
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                  >
                    <b-form-group
                      :label="$t('DNI')"
                    >
                      <b-form-input
                        v-model="form_data.fSearchRedeAgencia_NIF"
                        :class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                        placeholder=""
                        autocomplete="off"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-button
                    variant="outline-primary"
                    class="w-100"
                    @click.stop.prevent="getDataByNIPCAMI"
                  >
                    <span class="align-middle">{{ $t('Obter dados da agência') }}</span>
                  </b-button>
                </b-col>
              </b-row>
              <div v-if="loadInfoAgencia===true">
                <b-row>
                  <b-col md="12">
                    <hr>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Rede imobiliária')"
                    ><p
                       v-if="form_data.arrayAgencia172.sw172s26_txt !== undefined"
                       class="text-primary mb-0"
                     >
                       {{ form_data.arrayAgencia172.sw172s26_txt || '----' }}
                     </p>
                      <v-select
                        v-else
                        id="fSW070_flt128"
                        v-model="form_data.sw172s26"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="form_data.arrayAgencia128"
                        class="mb-1"
                        label="sw128s02"
                        item-text="sw128s02"
                        item-value="sw128s01"
                      >
                        <template #option="{ sw128s02 }">
                          {{ sw128s02 }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>

                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Designação comercial')"
                    />
                  </b-col>
                </b-row>
                <div :key="count">
                  <b-row
                    v-for="(sw175, index) in arraySW175"
                    :key="index"
                  >
                    <b-col
                      v-if="sw175.sw175s03 !== null && sw175.sw175s01 !== null"
                      md="12"
                    >
                      <b-form-group>
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            <b-form-checkbox
                              v-model="form_data.sw175s03_sel"
                              :value="index"
                            />
                          </b-input-group-prepend>
                          <div
                            class="form-control"
                            style="line-height: 1.8rem;"
                          >{{ sw175.sw175s03 || '----' }}
                          </div>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-else
                      md="12"
                    >
                      <b-form-group>
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            <b-form-checkbox
                              v-model="form_data.sw175s03_sel"
                              :value="index"
                            />
                          </b-input-group-prepend>
                          <b-form-input
                            v-model="arraySW175[index].sw175s03"
                            placeholder=""
                            autocomplete="off"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
                <b-row class="mb-1">
                  <b-col md="12">
                    <b-button
                      variant="outline-primary"
                      class="w-100"
                      @click.stop.prevent="addLinhaNovaAgencia"
                    >
                      <span class="align-middle">{{ $t('Nova agência') }}</span>
                    </b-button>
                  </b-col>
                </b-row>

                <div v-if="countryApp === 'pt'">
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        :label="$t('Nº Licença / AMI')"
                      ><p
                        class="text-primary mb-0"
                      >
                        {{ form_data.arrayAgencia172.sw172s11 || '----' }}
                      </p>

                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        :label="$t('NIPC')"
                      ><p
                        class="text-primary mb-0"
                      >
                        {{ form_data.arrayAgencia172.sw172s12 || '----' }}
                      </p>

                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        :label="$t('Designação social')"
                      ><p
                        class="text-primary mb-0"
                      >
                        {{ form_data.arrayAgencia172.sw172s02 || '----' }}
                      </p>

                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        :label="$t('Licença emitida em')"
                      ><p
                        class="text-primary mb-0"
                      >
                        {{ form_data.arrayAgencia172.sw172s13 || '----' }}
                      </p>

                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        :label="$t('Estado')"
                      ><p
                         v-if="form_data.arrayAgencia172.sw172s16 == 1"
                         class="text-primary mb-0"
                       >
                         {{ $t('Suspenso') }}
                       </p>
                        <p
                          v-else
                          class="text-primary mb-0"
                        >
                          {{ $t('Ativo') }}
                        </p>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
                <div v-if="countryApp !== 'pt'">
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        :label="$t('DNI')"
                      ><p
                        class="text-primary mb-0"
                      >
                        {{ form_data.arrayAgencia172.sw172s12 || '----' }}
                      </p>

                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        :label="$t('Designação social')"
                      ><p
                        class="text-primary mb-0"
                      >
                        {{ form_data.arrayAgencia172.sw172s02 || '----' }}
                      </p>

                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        :label="$t('Estado')"
                      ><p
                         v-if="form_data.arrayAgencia172.sw172s16 == 1"
                         class="text-primary mb-0"
                       >
                         {{ $t('Suspenso') }}
                       </p>
                        <p
                          v-else
                          class="text-primary mb-0"
                        >
                          {{ $t('Ativo') }}
                        </p>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>

                <component
                  :is="blockLocalization"
                  ref="location"
                />

              </div>

            </validation-observer></b-container>

        </vue-perfect-scrollbar>
        <div
          class="d-flex p-1 justify-content-center"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mr-2"
            @click.stop.prevent="closeModal"
          >
            {{ $t('Fechar') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click.stop.prevent="$emit('save-nova-agencia')"
          >
            {{ $t('Guardar') }}
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import store from '@/store'
import {
  BSidebar, BContainer, BRow, BCol, VBTooltip, BButton, BFormGroup, BFormInput, BFormCheckbox, BInputGroupPrepend, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { configSettingsScroll } from '@core-custom/utils/ui'

export default {
  components: {
    BSidebar,
    BContainer,
    BRow,
    BCol,
    BButton,
    VuePerfectScrollbar,
    vSelectDeselect: vSelect.components.Deselect,
    vSelect,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction],
  props: {
    showMsgErrorRequest: {
      type: Function,
      required: true,
    },
    showMsgSuccessRequest: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      modalData: [],
      count: 0,
    }
  },
  computed: {
    ...mapGetters('crm_visitas_form', ['form_data', 'arraySW175', 'loadInfoAgencia']),
    ...mapGetters('auth', ['countryApp']),
    numberPhoneValidate() {
      try {
        if (this.form_data.sw025s05) {
          return this.$refs.phone.phoneObject
        }
      } catch (err) {
        //
      }

      return null
    },
  },
  methods: {
    closeModal() {
      this.$emit('cancel-nova-agencia')
    },
    async getDataByNIPCAMI() {
      await this.$store.dispatch('crm_visitas_form/getDataByNIPCAMI').then(response => {
        if (response.error !== 1) {
          // console.log('111')
        } else {
          this.showMsgErrorRequest(new Error(response.msg))
        }
        this.$root.$emit('app::loading', false)
      }).catch(error => {
        // console.log('Response: error')
        this.$root.$emit('app::loading', false)
        this.showMsgErrorRequest(error)
      })
    },
    async addLinhaNovaAgencia() {
      await this.$store.dispatch('crm_visitas_form/addNovaAgencia').then(response => {
        if (response === undefined) {
          this.count += 1
        } else {
          this.showMsgErrorRequest(new Error(response.msg))
        }
        this.$root.$emit('app::loading', false)
      }).catch(error => {
        // console.log('Response: error')
        this.$root.$emit('app::loading', false)
        this.showMsgErrorRequest(error)
      })
    },
  },
  setup() {
    const countryApp = store.getters['auth/countryApp']
    const blockLocalization = () => import(/* webpackChunkName: "crm-visitas-form-localization" */ /* webpackPrefetch: true */ `./localization/${countryApp}.vue`)
    return {
      blockLocalization,
      configSettingsScroll,
    }
  },
}
</script>
